import React from 'react';
import { ISportResponse } from '../../../../../interfaces/sport.interface';
import { ListItem, Box, Typography, useMediaQuery } from '@mui/material';
import { colors } from '../../../../../theme/theme';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface IProps {
  sport: ISportResponse;
  selectedSportId: string | null;
  handleSportToggle: (sportId: string) => void;
  isEditSportBg?: boolean;
}

const SportListItem: React.FC<IProps> = ({
  sport,
  selectedSportId,
  handleSportToggle,
  isEditSportBg,
}) => {
  const isSelected = selectedSportId && selectedSportId === sport.id;

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ListItem
      onClick={() => handleSportToggle(sport.id)}
      sx={
        isEditSportBg
          ? {
              display: 'flex',
              padding: mobileView ? '6px' : '24px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              borderRadius: '1px',
              border: '1px solid',
              borderColor: isSelected ? 'button.primary' : 'background.dark',
            }
          : {
              display: 'flex',
              padding: mobileView ? '6px' : '24px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              borderRadius: '1px',
              border: '1px solid',
              borderColor: isSelected ? 'button.primary' : 'background.dark',
              cursor: 'pointer',
              transition: 'border-color 0.3s',
              '&:hover': {
                borderColor: 'button.primary',
                '& .sport-name': {
                  color: 'button.primary',
                },
              },
            }
      }
    >
      <Box
        display="flex"
        width="64px"
        height="64px"
        borderRadius="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          style={{
            position: 'relative',
            width: '48px',
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            src={sport.fileUrl}
            alt={sport.name}
          />
        </Box>
      </Box>

      <Typography
        variant="body3"
        className="sport-name"
        color={isSelected ? 'button.primary' : 'text.primary'}
        sx={{
          transition: 'color 0.3s',
          fontWeight: 700,
        }}
      >
        {sport.name}
      </Typography>
    </ListItem>
  );
};

export default SportListItem;
