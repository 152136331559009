import { Box, Container } from '@mui/material';
import LiveAgentCard from '../../../components/Profile/LiveAgentProfile/LiveAgentCard';
import ProfileCardContainer from '../../../components/Profile/ProfileCardContainer';
import ProfileSettingsMainContent from './components/ProfileSettingsMainContent';

const ProfileSettingsPage = () => {
  return (
    <Container
      disableGutters
      className="live-agent-profile-settings-page__container"
      sx={{
        maxWidth: '1320px !important',
      }}
    >
      <Box
        className={'live-agent-profile-settings-page__layout-container'}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={{ xs: '16px', xl: '22px' }}
        width="100%"
      >
        <ProfileCardContainer
          component="aside"
          className="live-agent-profile-settings-page__left-bar"
          cardData={<LiveAgentCard />}
          width={{ lg: 236, xl: 314 }}
        />
        <ProfileSettingsMainContent
          component="main"
          className="live-agent-profile-settings-page__main-content"
          display={'flex'}
          flexDirection={'column'}
          width="100%"
        />
      </Box>
    </Container>
  );
};

export default ProfileSettingsPage;
