import { useMemo, useState } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { useModalManager } from '../../../hooks/useModalManager';
import { PhotoAlbum } from '../../../interfaces/media.interface';
import CustomButton from '../../MUIComponents/CustomButton';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import MediaLayout from '../Layouts/MediaLayout';
import AddAlbumModal from '../Modals/AddAlbumModal';
import PhotoAlbumArea from './components/albums-tab/PhotoAlbumArea';
import SinglePhotoAlbum from './components/albums-tab/SinglePhotoAlbum';
import PhotoArea from './components/photos-tab/PhotoArea';
import { useMediaQuery } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import AddMediaMobileButton from '../AddMediaMobileButton';
import AddPhotosModal from '../Modals/AddPhotosModal';

const PHOTO_ALBUM = 'PHOTO_ALBUM';
const ADD_PHOTOS_MODAL = 'ADD_PHOTOS_MODAL';

enum WindowType {
  ALL_PHOTOS,
  ALL_ALBUMS,
}

const Photos = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [window, setWindow] = useState<WindowType>(WindowType.ALL_PHOTOS);
  const [album, setAlbum] = useState<PhotoAlbum | null>(null);
  const [isAlbumEditing, setIsAlbumEditing] = useState(false);
  const [isOpenAlbum, setIsOpenAlbum] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const { currentModal, toggleModal } = useModalManager();

  const addPhotosOrAlbumHandler = useMemo(() => {
    if (window === WindowType.ALL_PHOTOS || album) {
      return () => toggleModal(ADD_PHOTOS_MODAL);
    }

    return () => toggleModal(PHOTO_ALBUM);
  }, [album, window, toggleModal]);

  const handleBackToAlbums = () => {
    setAlbum(null);
    setIsOpenAlbum(false);
  };

  const handleOpenAlbum = () => {
    setIsOpenAlbum(true);
  };

  const addPhotosOrAlbumButton = useMemo(
    () => (
      <>
        {mobileView ? (
          <AddMediaMobileButton onClick={addPhotosOrAlbumHandler} />
        ) : (
          <CustomButton
            sx={{
              height: 42,
              width: { xs: '100%', md: 140 },
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            fontSize={'0.625rem'}
            onClick={addPhotosOrAlbumHandler}
          >
            {`add ${window === WindowType.ALL_PHOTOS || (album && !isAlbumEditing) ? 'photos' : 'album'}`}
          </CustomButton>
        )}

        {currentModal === PHOTO_ALBUM && (
          <AddAlbumModal
            isOpen
            onClose={() => toggleModal(PHOTO_ALBUM)}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}

        {currentModal === ADD_PHOTOS_MODAL && (
          <AddPhotosModal
            albumId={album?.id}
            isOpen
            onClose={() => toggleModal(ADD_PHOTOS_MODAL)}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
      </>
    ),
    [
      album,
      window,
      mobileView,
      currentModal,
      toggleModal,
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      addPhotosOrAlbumHandler,
      isAlbumEditing,
    ]
  );

  return (
    <>
      {isOpenAlbum && album && (
        <SinglePhotoAlbum
          album={album}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          handleBackToAlbums={handleBackToAlbums}
          addButton={addPhotosOrAlbumButton}
        />
      )}
      {!isOpenAlbum && (
        <MediaLayout
          title="Photos"
          isAllMedia={window === WindowType.ALL_PHOTOS}
          onAllMediaClick={() => setWindow(WindowType.ALL_PHOTOS)}
          onYourAlbumsClick={() => setWindow(WindowType.ALL_ALBUMS)}
          addButton={addPhotosOrAlbumButton}
        >
          {window === WindowType.ALL_PHOTOS && (
            <PhotoArea
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
          {window === WindowType.ALL_ALBUMS && (
            <PhotoAlbumArea
              album={album}
              setAlbum={album => setAlbum(album)}
              openAlbum={handleOpenAlbum}
              setIsAlbumEditing={setIsAlbumEditing}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
          <SnackbarCustom
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </MediaLayout>
      )}
    </>
  );
};

export default Photos;
