import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { OTHER_SPORT } from '../../../../../constants';
import { ITeam } from '../../../../../interfaces/user-sport-background.interface';
import { useGetSportsQuery } from '../../../../../store/apiSlice/sportApi';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { SportBackgroundFormInputs } from '../SportBackgroundItem/SportBackgroundItem';
import SportBackgroundTeamsBlock from '../SportBackgroundTeamsBlock/SportBackgroundTeamsBlock';
import SportFormSection from './SportFormSection';

export interface ITeamWithPosition {
  position: number;
  team: ITeam | null;
}

interface IProps {
  sportBg: SportBackgroundFormInputs | null;
  register: UseFormRegister<SportBackgroundFormInputs>;
  errors: FieldErrors<SportBackgroundFormInputs>;
  setFormValue: UseFormSetValue<SportBackgroundFormInputs>;
  getFormValues: UseFormGetValues<SportBackgroundFormInputs>;
  onFieldChange: (field: keyof SportBackgroundFormInputs, value: any) => void;
  disabled: boolean;
  control: Control<SportBackgroundFormInputs, any>;
  selectedSportId: string | null;
  sportName?: string;
}

const SportBackgroundFormBlock: React.FC<IProps> = ({
  sportBg,
  register,
  errors,
  setFormValue,
  getFormValues,
  onFieldChange,
  disabled,
  control,
  selectedSportId,
  sportName,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const {
    data: sports = [],
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useGetSportsQuery();

  const [teamWithPosition, setTeamWithPosition] = useState<ITeamWithPosition[]>(
    sportBg
      ? sportBg?.teams.map((team, index) => ({
          position: index + 1, // p.1 position must start from 1
          team: team,
        }))
      : [{ position: 1, team: null }]
  );

  useEffect(() => {
    const teams = teamWithPosition.map(item => item.team);
    onFieldChange('teams', teams);
  }, [teamWithPosition]);

  const [teamElements, setTeamElements] = useState<number[]>(
    sportBg?.teams.length
      ? Array.from(Array(sportBg?.teams.length + 1).keys()).slice(1)
      : [1]
  );

  useEffect(() => {
    if (teamElements?.length <= 0) {
      setTeamElements([1]);
    }
  }, [teamElements]);

  const addOneMoreTeam = () => {
    setTeamElements(prevState => {
      const maxElement = Math.max(...prevState);

      return [...prevState, maxElement + 1];
    });
  };

  const deleteTeam = (value: number) => {
    setTeamElements(prev =>
      prev.filter(backgroundValue => backgroundValue !== value)
    );
    setTeamWithPosition(prev =>
      prev.filter(teamWithPosition => teamWithPosition.position !== value)
    );
  };

  return (
    <Box display="flex" flexDirection={'column'}>
      <SportFormSection
        register={register}
        control={control}
        setFormValue={setFormValue}
        errors={errors}
        onFieldChange={onFieldChange}
        disabled={disabled}
        isOtherSport={selectedSportId === OTHER_SPORT.id}
      />
      {teamElements.map(value => {
        return (
          <Box key={value}>
            <SportBackgroundTeamsBlock
              savedTeam={sportBg?.teams[value - 1]} //p.2 index must match with teamElements index
              selectedSportName={
                sports.find(sport => sport.id === selectedSportId)?.name ??
                sportName ??
                OTHER_SPORT.name
              }
              teamElementPosition={value}
              deleteTeam={deleteTeam}
              teamWithPosition={teamWithPosition}
              setTeamWithPosition={setTeamWithPosition}
              register={register}
              control={control}
              errors={errors}
              disabled={disabled}
              isOtherSport={selectedSportId === OTHER_SPORT.id}
              isIndividualSport={
                (!!getFormValues('isIndividualSport') &&
                  selectedSportId === OTHER_SPORT.id) ||
                !!sportBg?.isIndividualSport
              }
            />
          </Box>
        );
      })}
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        border={'1px solid'}
        borderColor={'background.dark'}
        borderRadius={'4px'}
        padding={'1.375rem 0'}
        mt="28px"
        onClick={addOneMoreTeam}
        style={{ cursor: 'pointer' }}
      >
        <Typography typography={'badge2'}>ADD ONE MORE TEAM</Typography>
      </Box>
    </Box>
  );
};

export default SportBackgroundFormBlock;
