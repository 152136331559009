import { Box, SxProps } from '@mui/material';
import Header from '../components/Header/Header';
import { Outlet } from 'react-router-dom';
import { ReactNode } from 'react';
import Footer from '../components/Header/Footer/Footer';

interface IBaseLayoutProps {
  sx?: SxProps;
  children?: ReactNode;
}

const BaseLayout = ({ sx, children }: IBaseLayoutProps) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Header />
      <Box id={'profile-banner'}></Box>
      <Box
        display="flex"
        justifyContent="center"
        gap="22px"
        flexGrow={1}
        sx={{
          bgcolor: 'background.default',
          p: {
            xs: '20px 0px',
            md: '24px 0px',
            lg: '28px 16px',
            xl: '48px 60px',
          },
          ...sx,
        }}
      >
        {children || <Outlet />}
      </Box>
      <Footer />
    </Box>
  );
};

export default BaseLayout;
