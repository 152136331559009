import VerifyAccount from '../../../components/Auth/VerifyAccount/VerifyAccount';
import Header from '../../../components/Header/Header';
import { HeaderTypeEnum } from '../../../enums/header-type';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../../../store/selectors/authSelector';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../enums/cookie-enum';
import Footer from '../../../components/Header/Footer/Footer';

const VerifyAccountPage = () => {
  const authData = useSelector(selectAuthData);
  const role = authData?.user?.role || Cookies.get(CookieEnum.ROLE);
  return (
    <>
      <Header type={HeaderTypeEnum.VERIFY_ACCOUNT} />
      <VerifyAccount role={role} />
      <Footer />
    </>
  );
};

export default VerifyAccountPage;
