import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../enums/cookie-enum';
import { config } from '../../config/config';

const createBaseQuery = (baseUrl: string) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
      const refreshToken = Cookies.get(CookieEnum.REFRESH_TOKEN);
      if (token) {
        headers.set('Authorization', `Bearer ${token} ${refreshToken}`);
      }
      return headers;
    },
  });
};

export const baseQueryWithInterceptor = (baseUrl: string) => {
  const baseQuery = createBaseQuery(baseUrl);

  return async (args: any, api: any, extraOptions: any) => {
    let response = await baseQuery(args, api, extraOptions);

    if ((response as any)?.error?.status === 401) {
      const refreshTokenQuery = createBaseQuery(
        `${config.BASE_USER_MICROSERVICE_API_URL}/auth/refresh-token`
      );

      const refreshResponse = await refreshTokenQuery(
        { url: '', method: 'POST' },
        api,
        extraOptions
      );

      if (refreshResponse?.data) {
        const newAccessToken = (refreshResponse.data as any).accessToken;
        Cookies.set(CookieEnum.ACCESS_TOKEN, newAccessToken);

        response = await baseQuery(args, api, extraOptions);
      } else {
        Cookies.remove(CookieEnum.ACCESS_TOKEN);
        Cookies.remove(CookieEnum.REFRESH_TOKEN);
      }
    }

    return response;
  };
};
