import { Container, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { a11yProps } from '../../../../utils/a11yProps';
import RosterTab from '../tabs/roster-tab/RosterTab';
import ApplicantsListTab from '../tabs/applicants-list-tab/ApplicantsListTab';
import FeedBackTab from '../tabs/feedback-tab/FeedBackTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`live-agent-tabpanel-${index}`}
      aria-labelledby={`live-agent-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

interface LiveAgentTabsProps {
  hasAccess?: boolean;
  isBlocked?: boolean;
  isAccessLoading?: boolean;
}

const LiveAgentTabs = (props: LiveAgentTabsProps) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    const tabFromUrl = new URLSearchParams(location.search).get('tab');
    if (tabFromUrl && !isNaN(+tabFromUrl)) {
      setSelectedTab(+tabFromUrl);
    }
  }, [location.search]);

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        name: 'roster',
        container: <RosterTab />,
      },
      {
        name: 'list of applicants',
        container: <ApplicantsListTab />,
      },
      {
        name: 'feedback',
        container: <FeedBackTab />,
      },
    ];

    return baseTabs;
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    navigate(`?tab=${newValue}`);
  };

  return (
    <Box
      className="tabs-main-cointainer"
      sx={theme => ({
        width: '100%',
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'column',
        gap: '22px',
        paddingLeft: { xs: '8px', lg: '0' },
      })}
    >
      <Box
        className="tabs-main-cointainer__tabs-layout-container"
        sx={{
          //   padding: { xs: '16px 0 16px 16px', lg: '16px 0', xl: '22px 0' },
          width: '100%',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="live agent tabs"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            backgroundColor: theme.palette.background.white,
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((item, index) => (
            <Tab
              key={item.name}
              {...a11yProps(index)}
              label={item.name}
              sx={{
                flex: { xs: 'none', lg: 1 },
                borderRadius: '1px',
                color: theme.palette.text.primary,
                fontSize: '0.625rem',
                fontWeight: 800,
                textWrap: 'nowrap',
                letterSpacing: '0.025rem',
                padding: '1rem 1.375rem',
                width: 164,
                minWidth: 120,
                maxWidth: 166,
                '&.Mui-selected': {
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.white,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box className="tabs-main-cointainer__tab-panel-layout-container">
        {tabs.map((item, index) => (
          <TabPanel key={item.name} value={selectedTab} index={index}>
            <Container
              disableGutters
              className="tab-panel-container"
              maxWidth={false}
            >
              <Box className="tab-panel-content" width="100%">
                {item.container}
              </Box>
            </Container>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default LiveAgentTabs;
