import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import {
  useGetPhotoAlbumByIdQuery,
  useGetVideoAlbumByIdQuery,
} from '../../../store/apiSlice/profileMediaApi';
import BaseModal from '../../MUIComponents/BaseModal';
import CustomButton from '../../MUIComponents/CustomButton';
import GridSkeleton from '../GridSkeleton';

const LIMIT = 12;

interface ChooseCoverImageModalProps<T> {
  albumId: string | null;
  selectNewCoverImage: (file: T) => void;
  title: string;
  mediaType: 'photo' | 'video';
  isOpen?: boolean;
  onClose: () => void;
}

const ChooseCoverImageModal = <
  T extends { id: string; fileUrl?: string; videoThumbnail?: string },
>({
  albumId,
  selectNewCoverImage,
  title,
  mediaType,
  onClose,
  isOpen = false,
}: ChooseCoverImageModalProps<T>) => {
  const [mediaItems, setMediaItems] = useState<T[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [hasMorePressed, setHasMorePressed] = useState(false);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const laptopView = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  const skeletonItemsCount = useMemo(() => {
    if (mobileView) {
      return 2;
    } else if (tabletView) {
      return 3;
    } else if (laptopView) {
      return 4;
    } else {
      return 6;
    }
  }, [laptopView, mobileView, tabletView]);

  const queryFunction =
    mediaType === 'photo'
      ? useGetPhotoAlbumByIdQuery
      : useGetVideoAlbumByIdQuery;
  const {
    data: albumData,
    isLoading,
    isFetching,
  } = queryFunction(
    {
      id: albumId ?? '',
      page,
      limit: LIMIT,
    },
    { skip: !albumId }
  );

  useEffect(() => {
    if (albumData) {
      const newMediaItems = (albumData.data || []) as T[];
      const total = albumData.total || 0;

      const count = total - mediaItems.length;

      if (count > 0) {
        const newItemsToAdd = newMediaItems.slice(-count);

        setMediaItems(prevItems =>
          page === 1 ? newMediaItems : [...prevItems, ...newItemsToAdd]
        );
        setHasMorePressed(false);
        const hasNext = total !== 0 && page * LIMIT < total;
        setHasMore(hasNext);
      }
    }
  }, [albumData, page, mediaItems.length]);

  const handleNextPage = () => {
    if (hasMore) {
      setHasMorePressed(true);
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleSelectNewCoverImg = (file: T) => () => {
    selectNewCoverImage(file);
    onClose();
  };

  return (
    <BaseModal
      header={{ text: title }}
      isOpen={isOpen}
      toggle={onClose}
      disableDefaultFooter
    >
      <Box sx={{ textAlign: 'center' }} mt="36px">
        {isLoading && <GridSkeleton itemCount={skeletonItemsCount} />}

        {!isLoading && mediaItems.length ? (
          <Grid container spacing={2}>
            {mediaItems.map(file => (
              <Grid item xs={6} sm={4} lg={3} xl={2} key={file.id}>
                <Box
                  position="relative"
                  width="100%"
                  bgcolor="secondary2.main"
                  sx={{
                    cursor: 'pointer',
                    paddingBottom: '100%',
                  }}
                  onClick={handleSelectNewCoverImg(file)}
                >
                  <img
                    src={
                      mediaType === 'photo'
                        ? file.fileUrl || ''
                        : `data:image/png;base64,${file.videoThumbnail || ''}`
                    }
                    alt="Preview"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      maxWidth: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>
            This album is currently empty. Add images to the album to select a
            cover image.
          </Typography>
        )}

        {hasMorePressed && (
          <Box mt={2}>
            <GridSkeleton itemCount={skeletonItemsCount} />
          </Box>
        )}

        {hasMore && (
          <Box>
            <CustomButton
              sx={{
                height: 56,
                width: '100%',
                mt: { xs: 3, md: 6 },
              }}
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={handleNextPage}
              disabled={isFetching}
            >
              show more
            </CustomButton>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default ChooseCoverImageModal;
