import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { useGetFriendsByUserIdQuery } from '../../../../store/apiSlice/friendApi';
import SearchContacts from '../NewPersonalChat/SearchContacts';
import { Box, Typography } from '@mui/material';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import InviteFriendCard from './InviteFriendCard';
import {
  IGroupChat,
  useAddGroupChatMembersMutation,
} from '../../../../store/apiSlice/chat/groupApi';
import {
  getSelectedChat,
  setChatsLoaded,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import { IInviteFriendData } from '../../../../interfaces/friend.interface';

interface InvitationFormProps {
  onClose: () => void;
}

const InvitationForm: FC<InvitationFormProps> = ({ onClose }) => {
  const { theme } = useThemeContext();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedFriends, setSelectedFriends] = useState<IInviteFriendData[]>(
    []
  );
  const { data: myFriends } = useGetFriendsByUserIdQuery();
  const [addGroupChatMembers] = useAddGroupChatMembersMutation();
  const authData = useSelector(selectAuthData);
  const selectedChat = useSelector(getSelectedChat)! as IGroupChat;
  const dispatch = useDispatch();

  const filteredFriends: IInviteFriendData[] | undefined = useMemo(() => {
    const search = searchValue.toLocaleLowerCase();

    return myFriends?.friends
      .filter(
        ({ userId, firstName, lastName }) =>
          [firstName?.toLocaleLowerCase(), lastName?.toLocaleLowerCase()].some(
            name => name?.includes(search)
          ) && !selectedChat.members.some(member => member.id === userId)
      )
      .map(({ userId, firstName, lastName, avatarUrl, sports }) => ({
        id: userId,
        firstName: firstName!,
        lastName: lastName!,
        thumbnail: avatarUrl!,
        sports: sports || []
      }));
  }, [searchValue, myFriends?.friends, selectedChat.members]);

  const handleSelectFriend = (friend: IInviteFriendData) => () => {
    setSelectedFriends(prev => {
      const isSelected = prev.some(user => user.id === friend.id);
      return isSelected
        ? prev.filter(user => user.id !== friend.id)
        : [...prev, friend];
    });
  };

  const handleInvite = () => {
    addGroupChatMembers({
      groupId: selectedChat.id,
      addMemberDto: selectedFriends,
      userId: authData.user.id,
    })
      .unwrap()
      .then(group => {
        dispatch(setSelectedChat(group));
        dispatch(setChatsLoaded());
      })
      .catch(console.log);
    onClose();
  };

  return (
    <>
      <SearchContacts
        onSearch={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          borderBottom: '1px solid',
          borderColor: 'background.dark',
          overflow: 'scroll',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.75rem',
            padding: '1.75rem 1rem',
          }}
        >
          {myFriends && myFriends.pagination?.totalCount > 0 && (
            <Typography
              variant="body3"
              sx={{
                fontWeight: 700,
                color: theme.palette.text.primary,
              }}
            >
              Suggestions for you
            </Typography>
          )}
          <Box gap={'0.625rem'} display={'flex'} flexDirection={'column'}>
            {filteredFriends?.map((friend, index) => (
              <InviteFriendCard
                key={index}
                friend={friend}
                selectedFriends={selectedFriends}
                handleSelectFriend={handleSelectFriend(friend)}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
          borderTop: `1px solid ${theme.palette.background.dark}`,
        }}
      >
        <CustomButton
          variantType={ButtonTypeEnum.SECONDARY}
          onClick={onClose}
          buttonWidth={'13.75rem'}
        >
          Cancel
        </CustomButton>
        <CustomButton
          variantType={ButtonTypeEnum.PRIMARY}
          onClick={handleInvite}
          buttonWidth={'13.75rem'}
          disabled={selectedFriends.length === 0}
        >
          Invite
        </CustomButton>
      </Box>
    </>
  );
};

export default InvitationForm;
