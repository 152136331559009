import { Box, Input, useMediaQuery, useTheme } from '@mui/material';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { ElementIdEnum } from '../../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { Photo, PhotoAlbum } from '../../../../../interfaces/media.interface';
import {
  useAddUserPhotoMutation,
  useDeletePhotoMutation,
  useGetAllUserPhotosQuery,
  useGetPhotoAlbumByIdQuery,
  useGetAllUserPhotosByUserIdQuery,
} from '../../../../../store/apiSlice/profileMediaApi';
import { isSupportedIdConfirmationFileHelper } from '../../../../../utils/helper/file-validation-helper';
import { triggerFileInput } from '../../../../../utils/helper/triggerInputHelper';
import UploadedFileIconElement from '../../../../Auth/Elements/UploadedFileIconElement';
import PhotosIcon from '../../../../Icons/PhotosIcon';
import CustomButton from '../../../../MUIComponents/CustomButton';
import DragAndDropBox from '../../../../MUIComponents/DragAndDropBox/DragAndDropBox';
import PhotoGrid from './PhotoGrid';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import EmptyMediaBox from '../../../EmptyMediaBox';
import GridSkeleton from '../../../GridSkeleton';

interface PhotoAreaProps {
  album?: PhotoAlbum;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
  externalSave?: (files: File[]) => void;
}

const PhotoArea: FC<PhotoAreaProps> = ({
  album = null,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  externalSave,
}) => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [limit, setLimit] = useState<number>(12);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);
  const [hasMorePressed, setHasMorePressed] = useState(false);
  const { userId, isOwnPage } = useCurrentPageUserId();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletView = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const laptopView = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

  const skeletonItemsCount = useMemo(() => {
    if (mobileView) {
      return 2;
    } else if (tabletView) {
      return 3;
    } else if (laptopView) {
      return 4;
    } else {
      return 6;
    }
  }, [laptopView, mobileView, tabletView]);

  const useGetAllPhotos = useMemo(
    () =>
      album
        ? useGetPhotoAlbumByIdQuery
        : isOwnPage
          ? useGetAllUserPhotosQuery
          : useGetAllUserPhotosByUserIdQuery,
    [album, isOwnPage]
  );

  const {
    data: photoData,
    isLoading,
    isFetching,
  } = useGetAllPhotos(
    {
      id: album?.id || userId || '',
      page,
      limit,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [addUserPhoto, { isLoading: isUpdatingFiles }] =
    useAddUserPhotoMutation();
  const [deletePhoto] = useDeletePhotoMutation();

  useEffect(() => {
    if (photoData) {
      const newPhotos = photoData.data || [];
      const total = photoData.total || 0;

      const count = total - photos.length;
      //to do: need to check perhaps this logic is broken
      // case you have already 12 'photos' and total = 300, then 300 - 12 = 288
      // newPhotos with limit = 12 would be 12 elements, hence newPhotos.slice(-288)???
      const newItemsToAdd = newPhotos.slice(-count);

      if (count > 0) {
        setPhotos(prevPhotos =>
          page === 1 ? newPhotos : [...prevPhotos, ...newItemsToAdd]
        );
        setHasMorePressed(false);
        const hasNext = total !== 0 && page * limit < total;
        setHasMore(hasNext);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoData]);

  const handleNextPage = () => {
    if (hasMore) {
      setHasMorePressed(true);
      setPage(prevPage => prevPage + 1);
    }
  };

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const validFiles = newFiles.filter(file =>
      isSupportedIdConfirmationFileHelper(file)
    );
    const invalidFiles = newFiles.filter(
      file => !isSupportedIdConfirmationFileHelper(file)
    );

    if (validFiles.length > 5) {
      setSnackbarMessage('You can upload up to 5 files at once');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (invalidFiles.length > 0) {
      setSnackbarMessage('Invalid file type. Only images (JPEG, PNG, WebP)');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    try {
      if (externalSave) {
        externalSave(validFiles);
      } else {
        await Promise.all(
          validFiles.map(async file => {
            const formData = new FormData();
            formData.append('file', file);
            // if triggered from specific album, add photo to this album
            album && formData.append('photoAlbumId', album.id);
            await addUserPhoto(formData).unwrap();
          })
        );
      }
      if (validFiles.length + photos.length > limit * page) {
        setPage(1);
        const total = photoData?.total ? photoData?.total : 0;
        setLimit(validFiles.length + total);
      }
      setSnackbarMessage('All files uploaded successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error uploading files: ${error}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Error uploading files:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileRemove = async (id: string) => {
    setPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== id));
    await deletePhoto(id);
    setSnackbarMessage('Photo deleted successfully');
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    setSnackbarOpen(true);
  };

  const mainContent = !photos.length ? (
    isOwnPage ? (
      <DragAndDropBox
        handleDragOver={handleDragOver}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        triggerFileInput={() =>
          triggerFileInput(ElementIdEnum.ADD_PHOTOS_DRUG_AND_DROP)
        }
        fileInputId={ElementIdEnum.ADD_PHOTOS_DRUG_AND_DROP}
        text={
          <>
            Drag and drop your photos here
            <br />
            You can upload up to 5 files. Only images (JPEG, PNG, JPG, WebP) are
            accepted.
          </>
        }
        icon={
          <UploadedFileIconElement>
            <PhotosIcon active />
          </UploadedFileIconElement>
        }
      />
    ) : (
      <EmptyMediaBox
        text={`There are no albums`}
        icon={
          <UploadedFileIconElement>
            <PhotosIcon active />
          </UploadedFileIconElement>
        }
      />
    )
  ) : (
    <PhotoGrid files={photos} handleFileRemove={handleFileRemove} />
  );

  return (
    <>
      {isLoading || (isUpdatingFiles && photos.length === 0) ? (
        <GridSkeleton />
      ) : (
        mainContent
      )}
      {photos.length !== 0 && hasMorePressed && (
        <GridSkeleton itemCount={skeletonItemsCount} />
      )}
      {hasMore && (
        <Box>
          <CustomButton
            sx={{
              height: 56,
              width: '100%',
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={handleNextPage}
            disabled={isFetching}
          >
            show more
          </CustomButton>
        </Box>
      )}
      <Input
        id={ElementIdEnum.ADD_PHOTOS_BUTTON}
        type="file"
        sx={{ display: 'none' }}
        inputProps={{ multiple: true, accept: 'image/*' }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default PhotoArea;
