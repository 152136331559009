import { Box, useMediaQuery } from '@mui/material';
import CompanySidebarMenu from './CompanySidebarMenu';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const CompanySidebar = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box display="flex" flexDirection="column" gap="22px">
      {!mobileView && <CompanySidebarMenu />}
    </Box>
  );
};

export default CompanySidebar;
