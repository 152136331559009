import { Dispatch, FC, SetStateAction, useState } from 'react';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useModalManager } from '../../../../../hooks/useModalManager';
import { Photo, PhotoAlbum } from '../../../../../interfaces/media.interface';
import {
  useDeletePhotoAlbumMutation,
  useGetAllUserPhotoAlbumsByIdQuery,
  useGetAllUserPhotoAlbumsQuery,
} from '../../../../../store/apiSlice/profileMediaApi';
import GridSkeleton from '../../../GridSkeleton';
import EditAlbumModal from '../modals/EditAlbumModal';
import PhotoAlbumGrid from './PhotoAlbumGrid';
import ChooseCoverImageModal from '../../../Modals/ChooseCoverImageModal';

enum PhotoAlbumAreaModals {
  EDIT_ALBUM_MODAL = 'EDIT_ALBUM_MODAL',
  CHOOSE_COVER_IMAGE_MODAL = 'CHOOSE_COVER_IMAGE_MODAL',
}

interface PhotoAlbumAreaProps {
  album: PhotoAlbum | null;
  setAlbum: (album: PhotoAlbum | null) => void;
  openAlbum: () => void;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
  setIsAlbumEditing: Dispatch<SetStateAction<boolean>>;
}

const PhotoAlbumArea: FC<PhotoAlbumAreaProps> = ({
  album,
  setAlbum,
  openAlbum,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setIsAlbumEditing,
}) => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const { currentModal, toggleModal } = useModalManager();
  const [newCoverImage, setNewCoverImage] = useState<Photo | null>(null);

  const { data: photoAlbumData, isLoading: isLoadingOwnPage } =
    useGetAllUserPhotoAlbumsQuery(undefined, {
      skip: !isOwnPage,
    });

  const { data: userPhotoAlbumData, isLoading: isLoadingOtherPage } =
    useGetAllUserPhotoAlbumsByIdQuery(userId || '', {
      skip: isOwnPage,
    });

  const albums = isOwnPage ? photoAlbumData : userPhotoAlbumData;
  const isLoading = isLoadingOwnPage || isLoadingOtherPage;

  const [deletePhotoAlbum] = useDeletePhotoAlbumMutation();

  const handleFileRemove = async (id: string) => {
    await deletePhotoAlbum(id);
    setSnackbarMessage('Photo Album deleted successfully');
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    setSnackbarOpen(true);
  };

  const handleOpenAlbum = (album: PhotoAlbum) => {
    setAlbum(album);
    openAlbum();
  };

  const openEditAlbumModal = (album: PhotoAlbum) => {
    setAlbum(album);
    setIsAlbumEditing(true);
    toggleModal(PhotoAlbumAreaModals.EDIT_ALBUM_MODAL);
  };

  const closeEditAlbumModal = () => {
    setAlbum(null);
    setIsAlbumEditing(false);
    toggleModal(PhotoAlbumAreaModals.EDIT_ALBUM_MODAL);
  };

  const openChooseCoverImageModal = () =>
    toggleModal(PhotoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL);

  const closeChooseCoverImageModal = () =>
    toggleModal(PhotoAlbumAreaModals.EDIT_ALBUM_MODAL);

  return (
    <>
      {isLoading ? (
        <GridSkeleton />
      ) : (
        <>
          {album && (
            <>
              <EditAlbumModal
                album={album}
                title="Edit Album"
                newCoverImage={newCoverImage}
                isOpen={
                  currentModal === PhotoAlbumAreaModals.EDIT_ALBUM_MODAL ||
                  currentModal === PhotoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL
                }
                onClose={closeEditAlbumModal}
                openChooseCoverImageModal={openChooseCoverImageModal}
              />
              <ChooseCoverImageModal
                albumId={album.id}
                mediaType="photo"
                selectNewCoverImage={img => setNewCoverImage(img as Photo)}
                title="Choose a cover image"
                isOpen={
                  currentModal === PhotoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL
                }
                onClose={closeChooseCoverImageModal}
              />
            </>
          )}
          <PhotoAlbumGrid
            openAlbum={handleOpenAlbum}
            albums={albums}
            handleFileRemove={handleFileRemove}
            openAlbumEdit={openEditAlbumModal}
          />
        </>
      )}
    </>
  );
};

export default PhotoAlbumArea;
