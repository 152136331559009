import {
  Avatar,
  Box,
  Button,
  ButtonProps,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LikeIcon from '../../../../components/Icons/LikeIcon';
import RepostIcon from '../../../../components/Icons/RepostIcon';
import SendIcon from '../../../../components/Icons/SendIcon';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { Suggestion } from '../../../../components/shared/Mentions/MentionsTextField/MentionsTextField';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useModalManager } from '../../../../hooks/useModalManager';
import { ICommentResponse } from '../../../../interfaces/comment.interface';
import { IPost } from '../../../../interfaces/post.interface';
import {
  useCreateCommentMutation,
  useGetPostCommentsQuery,
} from '../../../../store/apiSlice/post/commentApi';
import {
  useDislikePostMutation,
  useLikePostMutation,
} from '../../../../store/apiSlice/post/postReactionApi';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import { colors } from '../../../../theme/theme';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { errorHelper } from '../../../../utils/helper/error-helper';
import CreatePostModal from '../modals/CreatePostModal';
import PostCommentsModal from '../modals/PostCommentsModal';
import RepostModal from '../modals/RepostModal';
import ShareModal from '../modals/ShareModal';
import CommentField from './CommentField';
import { PostType } from './NewPublicationTrigger';
import PostBodyText from './PostBodyText';
import PostData from './PostData';
import PostHeader from './PostHeader';
import ProfilePostMoreMenu from './ProfilePostMoreMenu';
import RepostedPost from './RepostedPost';

type PostButtonsProps = React.PropsWithChildren<ButtonProps>;

export const PostButtons = ({ children, ...props }: PostButtonsProps) => {
  return (
    <Button
      variant="text"
      fullWidth
      sx={{
        borderRadius: '4px',
        backgroundColor: '#F6F7F8',
        color: 'text.secondary',
        fontSize: '10px',
        columnGap: '12px',
        padding: '12px 16px',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

interface IProfilePostProps {
  post: IPost;
  refetchPosts: () => void;
}

export enum MyPostModals {
  CREATE_POST_MODAL = 'CREATE_POST_MODAL',
  POST_WITH_COMMENTS_MODAL = 'POST_WITH_COMMENTS_MODAL',
  REPOST_MODAL = 'REPOST_MODAL',
  SHARE_MODAL = 'SHARE_MODAL',
}

const COMMENTS_LIMIT = 5;

const ProfilePost: React.FC<IProfilePostProps> = ({ post, refetchPosts }) => {
  const { theme } = useThemeContext();
  const { currentModal, toggleModal } = useModalManager();
  const [selectedPostType, setSelectedPostType] = useState<PostType>(
    PostType.NONE
  );

  const { user: myData } = useSelector(selectAuthData);
  const { data: userData } = useGetUserInfoQuery(post.userId);

  const [postCommentsPage, setPostCommentsPage] = useState<number>(1);

  const commentsParams = {
    postId: post.id,
    page: postCommentsPage,
    limit: COMMENTS_LIMIT,
  };

  const { data: postCommentsData } = useGetPostCommentsQuery(commentsParams, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  useEffect(() => {
    if (postCommentsData?.total) setCommentsCount(postCommentsData.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCommentsData?.total]);

  const [mentions, setMentions] = useState<Suggestion[]>([]);
  const [commentMsg, setCommentMsg] = useState<string>('');
  const [likesCount, setLikesCount] = useState<number>(post.likesCount);

  const [commentsCount, setCommentsCount] = useState<number>(0);

  const [isLiked, setIsLiked] = useState<boolean>(
    isPostLikedByUser(post.reactions, myData?.id as string) || false
  );

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  function isPostLikedByUser(postReactions: any[], userId: string) {
    return postReactions.some(
      reaction => reaction.userId === userId && reaction.type === 'LIKE'
    );
  }

  const [likePost, { isLoading: isLiking }] = useLikePostMutation();

  const handleLike = async (postId: string) => {
    try {
      await likePost(postId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev + 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Post liked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to like the post: ', error);
    }
  };
  const [dislikePost, { isLoading: isDisliking }] = useDislikePostMutation();

  const handleDislike = async (postId: string) => {
    try {
      await dislikePost(postId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev - 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Post disliked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Dislike failed: ', error);
    }
  };

  const [commentPost, { isLoading: isCommenting }] = useCreateCommentMutation();

  const handleComment = async (postId: string, mentions: Suggestion[]) => {
    if (commentMsg) {
      try {
        await commentPost({ postId, message: commentMsg, mentions }).unwrap();
        setCommentsCount(prev => prev + 1);
        setSnackbarOpen(true);
        setSnackbarMessage('Post commented!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setCommentMsg('');
        setPostCommentsPage(1);
        // refetchComments();
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        console.error('Comment failed: ', error);
      }
    }
  };

  const onSendCommentClick = () => {
    handleComment(post.id, mentions);
    toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL);
  };

  const filesArray = post.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });

  return (
    <>
      {currentModal === MyPostModals.CREATE_POST_MODAL && (
        <CreatePostModal
          refetchPosts={refetchPosts}
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.CREATE_POST_MODAL);
          }}
          selectedPostType={selectedPostType}
        />
      )}
      {currentModal === MyPostModals.REPOST_MODAL && (
        <RepostModal
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.REPOST_MODAL);
          }}
          post={post}
        />
      )}
      {currentModal === MyPostModals.SHARE_MODAL && (
        <ShareModal
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.SHARE_MODAL);
          }}
          post={post}
        />
      )}
      {currentModal === MyPostModals.POST_WITH_COMMENTS_MODAL &&
        post &&
        userData && (
          <PostCommentsModal
            isOpen
            onClose={() => {
              toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL);
            }}
            onSendCommentClick={onSendCommentClick}
            post={post}
            userData={userData}
            likesCount={likesCount}
            commentsCount={commentsCount}
            isLiked={isLiked}
            isLiking={isLiking}
            isDisliking={isDisliking}
            isCommenting={isCommenting}
            handleDislike={handleDislike}
            handleLike={handleLike}
            postCommentsPage={postCommentsPage}
            setPostCommentsPage={setPostCommentsPage}
            setSelectedPostType={setSelectedPostType}
            toggleModal={toggleModal}
            setCommentMsg={setCommentMsg}
            setMentions={mentions => setMentions(mentions)}
            postCommentsData={postCommentsData as ICommentResponse}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
      <Container disableGutters>
        <PostHeader
          post={post}
          userData={userData}
          editButton={
            <ProfilePostMoreMenu
              post={post}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          }
          sx={{
            padding: '1rem',
          }}
        />

        <Box
          component="article"
          onClick={() => toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL)}
          sx={{ cursor: 'pointer' }}
        >
          <PostBodyText
            post={post}
            sx={{
              padding: '1rem',
            }}
          />

          {!post.repostedPostId ? (
            <QuiltedImageList itemData={filesArray} toggleModal={toggleModal} />
          ) : (
            <Box
              sx={{
                padding: '0 1rem',
              }}
            >
              <RepostedPost repostedPostId={post.repostedPostId} />
            </Box>
          )}

          <PostData
            likesCount={likesCount}
            commentsCount={commentsCount}
            repostsCount={post.repostsCount}
          />
        </Box>

        <Box
          component="footer"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.375rem',
            padding: '1.375rem 1rem',
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{ gridColumnGap: '0.75rem' }}
          >
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  isLiked ? handleDislike(post.id) : handleLike(post.id);
                }}
                disabled={isLiking || isDisliking}
              >
                <LikeIcon fillNone={!isLiked} isRed={isLiked} />
                <Typography
                  variant={'badge3'}
                  sx={{
                    color: isLiked ? colors.bgRed : 'text.secondary',
                  }}
                >
                  {` Like${isLiked ? 'd' : ''}`}
                </Typography>
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.REPOST_MODAL);
                }}
              >
                <RepostIcon />
                Repost
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.SHARE_MODAL);
                }}
              >
                <SendIcon />
                Share
              </PostButtons>
            </Grid>
          </Grid>

          <Grid container columnGap={2}>
            <Grid item height="fit-content">
              <Avatar
                alt="Profile"
                src={
                  myData && myData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item flex={1} minWidth="1px" component={'form'}>
              <CommentField
                disabled={isCommenting}
                sendComment={onSendCommentClick}
                setCommentMsg={value => setCommentMsg(value)}
                setMentions={mentions => setMentions(mentions)}
              />
            </Grid>
          </Grid>
        </Box>

        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Container>
    </>
  );
};

export default ProfilePost;
