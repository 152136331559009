import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import PhotosIcon from '../../../../components/Icons/PhotosIcon';
import VideosIcon from '../../../../components/Icons/VideosIcon';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import Button from '../../../../components/MUIComponents/Button';
import ButtonWithIcon from '../../../../components/MUIComponents/ButtonWithIcon';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import AddMedia from '../../../../components/Media/AddMedia/AddMedia';
import MentionsTextFieldWrapper from '../../../../components/shared/Mentions/MentionsTextFieldWrapper/MentionsTextFieldWrapper';
import { ElementIdEnum } from '../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useCreatePostMutation } from '../../../../store/apiSlice/post/postApi';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { createPostSchema } from '../../../../validation/post-validation';
import { PostType } from '../posts/NewPublicationTrigger';
import { Suggestion } from '../../../../components/shared/Mentions/MentionsTextField/MentionsTextField';

interface CreatePostModalProps {
  selectedPostType: PostType;
  isOpen?: boolean;
  onClose: () => void;
  refetchPosts: () => void;
}

interface CreatePostFormInputs {
  content?: string;
  mentions?: Suggestion[];
  title?: string | null;
  repostedPostId?: string | null;
}

const CreatePostModal = ({
  selectedPostType,
  onClose,
  isOpen = false,
  refetchPosts,
}: CreatePostModalProps) => {
  const [photoBtnActive, setPhotoBtnActive] = useState<boolean>(
    PostType.PHOTO === selectedPostType
  );
  const [videoBtnActive, setVideoBtnActive] = useState<boolean>(
    PostType.VIDEO === selectedPostType
  );
  const theme = useTheme();
  const [imgFiles, setImgFiles] = useState<File[]>([]);
  const [videoFiles, setVideoFiles] = useState<File[]>([]);
  const matches = useMediaQuery('(max-width:1141px)');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const togglePhotoBtn = useCallback(() => {
    setImgFiles([]);
    setPhotoBtnActive(prevState => !prevState);
  }, []);
  const toggleVideoBtn = useCallback(() => {
    setVideoFiles([]);
    setVideoBtnActive(prevState => !prevState);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CreatePostFormInputs>({
    resolver: yupResolver(createPostSchema),
  });

  const [createPost, { isLoading }] = useCreatePostMutation();

  const imgFilesRef = useRef<File[]>([]);
  const videoFilesRef = useRef<File[]>([]);

  useEffect(() => {
    imgFilesRef.current = imgFiles;
    videoFilesRef.current = videoFiles;
  }, [imgFiles, videoFiles]);

  const onSubmit = async (data: CreatePostFormInputs) => {
    setIsSubmitting(true);
    try {
      if (
        !data.content &&
        !imgFilesRef.current.length &&
        !videoFilesRef.current.length
      ) {
        throw new Error("You can't create an empty post!");
      }
      const formData = new FormData();
      data.title = Date.now().toString();

      formData.append('title', data.title);
      formData.append('content', data?.content ?? '');

      data.mentions?.forEach((mention, index) => {
        (Object.keys(mention) as (keyof Suggestion)[]).forEach(key => {
          const typedKey = key as keyof Suggestion;
          const value =
            mention[typedKey] !== undefined ? String(mention[typedKey]) : '';

          return formData.append(`mentions[${index}][${key}]`, value);
        });
      });

      if (imgFilesRef.current.length > 0) {
        imgFilesRef.current.forEach(file => {
          formData.append('postFiles', file);
        });
      }

      if (videoFilesRef.current.length > 0) {
        videoFilesRef.current.forEach(file => {
          formData.append('postFiles', file);
        });
      }

      if (data.repostedPostId) {
        formData.append('repostedPostId', data.repostedPostId);
      }

      await createPost(formData).unwrap();
      refetchPosts();
      setSnackbarMessage('Post created successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      onClose();
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Create post failed:', error);
    } finally {
      setIsSubmitting(false);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { user: myData } = useSelector(selectAuthData);

  const modalTitle = useMemo(
    () => (
      <Box display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Avatar
            alt="Profile"
            src={
              myData?.optimizedUserAvatarBaseUrl
                ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                : ''
            }
            sx={{ width: 40, height: 40 }}
          />
          <Typography variant="subtitle1" alignContent="center">
            {`${myData?.firstName || ''} ${myData?.lastName || ''}`}
          </Typography>
        </Stack>
      </Box>
    ),
    [myData]
  );

  const modalFooter = useMemo(
    () => (
      <Box
        mt={3}
        sx={
          matches
            ? {
                '& > .MuiGrid-root': {
                  gap: '15px',
                  '& .MuiGrid-root:nth-of-type(2)': {
                    width: '100%',
                    button: {
                      width: '100%',
                    },
                  },
                },
                '& .photo-video-btn-container': {
                  width: '100%',
                  '& .dumb-box': {
                    flex: 1,
                  },
                  button: {
                    width: '100%',
                  },
                },
              }
            : {}
        }
      >
        <Grid container justifyContent="space-between">
          <Grid
            className="photo-video-btn-container"
            item
            container
            columnGap={2}
            width="fit-content"
          >
            <Box
              className="dumb-box"
              onClick={!isLoading && !isSubmitting ? togglePhotoBtn : undefined}
            >
              <ButtonWithIcon
                selected={photoBtnActive}
                disabled={isLoading || isSubmitting}
                icon={
                  <PhotosIcon
                    height={20}
                    width={20}
                    iconColor={
                      photoBtnActive ? theme.palette.button.primaryText : ''
                    }
                  />
                }
                sx={{
                  minWidth: '6.875rem',
                }}
              >
                Photo
              </ButtonWithIcon>
            </Box>
            <Box
              className="dumb-box"
              onClick={!isLoading && !isSubmitting ? toggleVideoBtn : undefined}
            >
              <ButtonWithIcon
                selected={videoBtnActive}
                disabled={isLoading || isSubmitting}
                icon={
                  <VideosIcon
                    height={20}
                    width={20}
                    iconColor={
                      videoBtnActive ? theme.palette.button.primaryText : ''
                    }
                  />
                }
                sx={{
                  minWidth: '6.875rem',
                }}
              >
                Video
              </ButtonWithIcon>
            </Box>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              width={220}
              height={50}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || isSubmitting}
            >
              {isSubmitting ? 'Publishing...' : 'Publish'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    ),
    [
      photoBtnActive,
      videoBtnActive,
      togglePhotoBtn,
      toggleVideoBtn,
      matches,
      theme,
      isLoading,
      isSubmitting,
    ]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      boxSx={{ width: { xs: '100%', lg: '875px' } }}
    >
      <Stack spacing="36px">
        <Box>
          <MentionsTextFieldWrapper
            minHeight={140}
            maxLength={500}
            setMentions={mentions => setValue('mentions', mentions)}
            placeholder="Write your thoughts here..."
            register={register('content')}
            error={!!errors.content}
            helperText={errors.content ? errors.content.message : ''}
            disabled={isLoading || isSubmitting}
          />
        </Box>
        {photoBtnActive && (
          <Box className="add-media-container" position="relative">
            <IconButton
              onClick={!isLoading && !isSubmitting ? togglePhotoBtn : undefined}
              sx={{ position: 'absolute', top: 0, right: 0 }}
              disabled={isLoading || isSubmitting}
            >
              <CloseIcon />
            </IconButton>
            <AddMedia
              fileInputId={ElementIdEnum.ADD_POST_PHOTOS}
              text="Drag and drop your photos here. You can upload up to 10 files. Only images (JPEG, PNG, JPG, WebP) are accepted."
              icon={<PhotosIcon active />}
              setCentralFiles={setImgFiles}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
              setSnackbarOpen={setSnackbarOpen}
            />
          </Box>
        )}
        {videoBtnActive && (
          <Box className="add-media-container" position="relative">
            <IconButton
              onClick={!isLoading && !isSubmitting ? toggleVideoBtn : undefined}
              sx={{ position: 'absolute', top: 0, right: 0 }}
              disabled={isLoading || isSubmitting}
            >
              <CloseIcon />
            </IconButton>
            <AddMedia
              fileInputId={ElementIdEnum.ADD_POST_VIDEOS}
              text="Drag and drop your videos here. Only videos are accepted."
              icon={<VideosIcon active />}
              isVideoInput={true}
              setCentralFiles={setVideoFiles}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
              setSnackbarOpen={setSnackbarOpen}
            />
          </Box>
        )}
      </Stack>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default CreatePostModal;
