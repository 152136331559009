import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  FriendDataResponse,
  IApproveFriendInvitationRequest,
  ICancelFriendInvitationRequest,
  IDeleteFriendInviteTokenRequest,
  IFriendInvitationResponse,
  IFriendMyInvitationResponse,
  IGenerateFriendInvitationRequest,
  IInviteToFriendRequest,
} from '../../interfaces/friend-invitation.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const friendInvitationApi = createApi({
  reducerPath: 'friendInvitationApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_FRIEND_MICROSERVICE_API_URL}/friend-invitation`
  ),
  tagTypes: ['friendInvitation'],

  endpoints: builder => ({
    generateFriendInvitation: builder.mutation<
      IFriendInvitationResponse,
      IGenerateFriendInvitationRequest
    >({
      query: data => ({
        url: `inviteUserId/${data.inviteUserId}`,
        method: 'POST',
      }),
      invalidatesTags: ['friendInvitation'],
    }),
    getAllFriendInviteTokens: builder.query<IFriendInvitationResponse[], void>({
      query: () => '',
      providesTags: ['friendInvitation'],
    }),

    getAllMyInvitations: builder.query<
      IFriendMyInvitationResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};
        return `/my-invitations?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friendInvitation'],
    }),

    getAllInvitationsForMe: builder.query<
      IFriendMyInvitationResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};

        return `/invitations-for-me?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friendInvitation'],
    }),

    inviteToFriend: builder.mutation<void, IInviteToFriendRequest>({
      query: ({ friendUserId }) => ({
        url: `invite-to-friend/${friendUserId}`,
        method: 'POST',
      }),
      invalidatesTags: ['friendInvitation'],
    }),

    approveFriendInvitation: builder.mutation<
      IFriendInvitationResponse,
      IApproveFriendInvitationRequest
    >({
      query: ({ friendInviteToken }) => ({
        url: `approve-friend-invitation?friendInviteToken=${friendInviteToken}`,
        method: 'POST',
      }),
      invalidatesTags: ['friendInvitation'],
    }),

    cancelFriendInvitation: builder.mutation<
      void,
      ICancelFriendInvitationRequest
    >({
      query: ({ friendInviteToken }) => ({
        url: `cancel-friend-invitation?friendInviteToken=${friendInviteToken}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friendInvitation'],
    }),

    deleteInviteToFriend: builder.mutation<
      void,
      IDeleteFriendInviteTokenRequest
    >({
      query: ({ friendUserId }) => ({
        url: `delete-invite-to-friend/${friendUserId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friendInvitation'],
    }),
    deleteFriendInviteToken: builder.mutation<
      void,
      IDeleteFriendInviteTokenRequest
    >({
      query: ({ friendUserId }) => ({
        url: `friendUserId/${friendUserId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friendInvitation'],
    }),
    deleteUserFromAllFriendInvitations: builder.mutation<void, void>({
      query: () => ({
        url: `delete-my-from-all-invitations`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friendInvitation'],
    }),
    checkIfMyFriendInvitationExist: builder.query<
      FriendDataResponse<boolean>,
      string
    >({
      query: userId => ({
        url: `check-is-my-invitation-exist/${userId}`,
      }),
      providesTags: ['friendInvitation'],
    }),
  }),
});

export const {
  useGenerateFriendInvitationMutation,
  useInviteToFriendMutation,
  useGetAllFriendInviteTokensQuery,
  useGetAllMyInvitationsQuery,
  useGetAllInvitationsForMeQuery,
  useApproveFriendInvitationMutation,
  useCancelFriendInvitationMutation,
  useDeleteInviteToFriendMutation,
  useDeleteFriendInviteTokenMutation,
  useDeleteUserFromAllFriendInvitationsMutation,
  useCheckIfMyFriendInvitationExistQuery,
} = friendInvitationApi;

export default friendInvitationApi;
