import * as yup from 'yup';
import { ValidationError } from 'yup';

const today = new Date();

const leagueAndTeamRegex = (
  fieldName: string,
  value?: string | null
): boolean | ValidationError => {
  if (!value) return true;
  if (value.length < 2)
    return new yup.ValidationError(
      `${fieldName} must be at least 2 characters long.`
    );
  if (value.length > 50)
    return new yup.ValidationError(
      `${fieldName} must be at most 50 characters long.`
    );
  if (!/^[A-Za-z\s]+$/.test(value))
    return new yup.ValidationError(
      `${fieldName} can only contain letters and spaces.`
    );
  return true;
};

export const sportBackgroundSchema = yup.object().shape({
  description: yup
    .string()
    .max(500, 'File description must be between 0 and 500 characters.'),
  links: yup.array().of(yup.string().url('Each link must be a valid URL.')),
});

export const sportSchema = yup.object().shape({
  sportIds: yup
    .array()
    .of(yup.string().required('Sport is required'))
    .min(1, 'At least one sport is required')
    .required('Sports are required'),
});

export const createTeamSchema = (isOtherSport: boolean) => {
  return yup.object().shape({
    isIndividualSport: yup
      .boolean()
      .required('isIndividualSport is required')
      .default(false),
    leagueId: yup.string().nullable(),
    teamId: yup.string().nullable(),
    leagueName: yup
      .string()
      .transform((value: string) => value.replace(/\s+/g, ' ').trim())
      .matches(/^[A-Za-z0-9\s_\-'.?]+$/, {
        message:
          'The league name can only contain letters, numbers, spaces, underscores, hyphens, dots, apostrophes, and question marks.',
      })
      .min(2, 'The league name must be at least 2 characters long.')
      .max(80, 'The league name must be at most 80 characters long.')
      .when('leagueId', {
        is: (leagueId: string) => leagueId !== null,
        then: schema => schema.required('League is required'),
      }),
    teamName: yup
      .string()
      .nullable()
      .when('isIndividualSport', {
        is: false,
        then: schema =>
          schema
            .transform((value: string) => value.replace(/\s+/g, ' ').trim())
            .required('Team is required')
            .transform((value: string | null) =>
              value ? value.replace(/\s+/g, ' ').trim() : value
            )
            .matches(/^[A-Za-z0-9\s_\-'.?]*$/, {
              message:
                'The team name can only contain letters, numbers, spaces, underscores, hyphens, dots, apostrophes, and question marks.',
            })
            .min(2, 'The team name must be at least 2 characters long.')
            .max(80, 'The team name must be at most 80 characters long.'),
        otherwise: schema => schema.nullable().default(null),
      }),
    startDateInTeam: yup
      .date()
      .required('Start career date in team is required')
      .max(new Date(), 'Start career date in team cannot be in the future'),
    endDateInTeam: yup
      .date()
      .required('End career date in team is required')
      .min(
        yup.ref('startDateInTeam'),
        'End career date in team must be greater than start career in team date'
      )
      .test(
        'is-greater',
        'End career date in team must be greater than start career date in team ',
        function (value) {
          const { startDateInTeam } = this.parent;
          return value > startDateInTeam;
        }
      ),
  });
};

export const createSportBackgroundSchema = (isOtherSport: boolean) => {
  const sportName = isOtherSport
    ? yup
        .string()
        .required('Sport name is required')
        .min(2, 'Sport should be at least 2 characters long.')
        .max(70, 'Sport should be at most 70 characters long.')
    : yup.string().nullable();

  return yup.object().shape({
    isOtherSport: yup.boolean().default(false),
    sportName: sportName,
    startCareerDate: yup
      .date()
      .required('Start career date is required')
      .max(today, 'Start career date cannot be in the future'),
    endCareerDate: yup
      .date()
      .required('End career date is required')
      .min(
        yup.ref('startCareerDate'),
        'End career date must be greater than start career date'
      )
      .test(
        'is-greater',
        'End career date must be greater than start career date',
        function (value) {
          const { startCareerDate } = this.parent;
          return value > startCareerDate;
        }
      ),

    description: yup
      .string()
      .nullable()
      .notRequired()
      .min(10, 'Description should be at least 10 characters long.')
      .max(500, 'Description should be at most 500 characters long.'),

    fileData: yup.array().of(sportBackgroundSchema),

    teams: yup
      .array()
      .of(createTeamSchema(isOtherSport))
      .min(1, 'At least one team is required')
      .when('isOtherSport', {
        is: true,
        then: schema => schema.nullable().default(null),
        otherwise: schema => schema.required('Teams are required'),
      }),
  });
};
