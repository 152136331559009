import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  Control,
  FieldError,
  FieldErrors,
  useForm,
  UseFormRegister,
} from 'react-hook-form';
import { ILeague } from '../../../../../interfaces/career.interface';
import {
  useGetLeagueBySportNameQuery,
  useGetTeamsByLeagueNameQuery,
} from '../../../../../store/apiSlice/careerApi';
import { withUnderScore } from '../../../../../utils/withUnderScore';
import { createTeamSchema } from '../../../../../validation/sport-validation';
import DeleteFileRedIcon from '../../../../Icons/DeleteFileRedIcon';
import DateInput from '../../../../MUIComponents/DateInput';
import { ITeamWithPosition } from '../SportBackgroundFormBlock/SportBackgroundFormBlock';
import {
  SportBackgroundFormInputs,
  TeamFormInputs,
} from '../SportBackgroundItem/SportBackgroundItem';
import Leagues from './Leagues';
import Teams from './Teams';
import { ObjectSchema } from 'yup';

const defaultFormState = {
  isIndividualSport: false,
  leagueId: '',
  leagueName: '',
  teamId: '',
  teamName: '',
  startDateInTeam: new Date(new Date().getTime() - 60000),
  endDateInTeam: new Date(new Date().getTime() + 1000),
};

interface SportBackgroundTeamsBlockProps {
  savedTeam?: TeamFormInputs;
  selectedSportName: string | null;
  teamElementPosition: number;
  teamWithPosition: ITeamWithPosition[];
  setTeamWithPosition: React.Dispatch<
    React.SetStateAction<ITeamWithPosition[]>
  >;
  deleteTeam: (value: number) => void;
  register: UseFormRegister<SportBackgroundFormInputs>;
  errors: FieldErrors<SportBackgroundFormInputs>;
  control: Control<SportBackgroundFormInputs, any>;
  isOtherSport: boolean;
  isIndividualSport: boolean;
  disabled: boolean;
}

const SportBackgroundTeamsBlock = ({
  savedTeam,
  selectedSportName,
  teamElementPosition,
  teamWithPosition,
  setTeamWithPosition,
  deleteTeam,
  isOtherSport,
  isIndividualSport,
  disabled,
}: SportBackgroundTeamsBlockProps) => {
  const [typedLeague, setTypedLeague] = useState<string>('');
  const [typedTeam, setTypedTeam] = useState<string>('');

  const {
    data: leagues = [],
    isLoading: isLeagueLoading,
    isError: isLeagueErrors,
  } = useGetLeagueBySportNameQuery(withUnderScore(selectedSportName || ''), {
    skip: !Boolean(selectedSportName),
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
  });

  const [selectedLeague, setSelectedLeague] = useState<ILeague | null>(null);

  const {
    data: teams = [],
    isLoading: isTeamLoading,
    isError: isTeamError,
  } = useGetTeamsByLeagueNameQuery(
    withUnderScore(selectedLeague?.strLeague || typedLeague || 'other')
  );

  const [formState, setFormState] = useState<TeamFormInputs>(
    savedTeam ?? defaultFormState
  );

  const teamSchema = createTeamSchema(
    isOtherSport
  ) as ObjectSchema<TeamFormInputs>;

  const {
    watch,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm<TeamFormInputs>({
    resolver: yupResolver(teamSchema),
    defaultValues: savedTeam ? teamSchema.cast(savedTeam) : defaultFormState,
    mode: 'onChange',
  });

  useEffect(() => {
    if (!savedTeam) {
      reset(defaultFormState, { keepValues: false });
      setFormState(defaultFormState);
    }
  }, [selectedSportName, reset, savedTeam]);

  useEffect(() => {
    updateTeamWithPosition(formState);
  }, [formState, selectedSportName]);

  useEffect(() => {
    if (!savedTeam) {
      setSelectedLeague(null);
      setFormState(defaultFormState);
      handleFieldChange('isIndividualSport', isIndividualSport);
    }
  }, [isIndividualSport, savedTeam]);

  useEffect(() => {
    if (!savedTeam) {
      setTypedLeague('');
      setTypedTeam('');
      handleFieldChange('leagueId', '');
      handleFieldChange('teamId', '');
      handleFieldChange('teamName', '');
      handleFieldChange('leagueName', '');
      handleFieldChange('isIndividualSport', isIndividualSport);
      setTypedLeague('');
      setSelectedLeague(null);
    }
  }, [selectedSportName, isIndividualSport]);

  const updateTeamWithPosition = ({
    isIndividualSport,
    teamId,
    leagueId,
    teamName,
    leagueName,
    endDateInTeam,
    startDateInTeam,
  }: Partial<TeamFormInputs>) => {
    setTeamWithPosition(prevState => {
      const existingIndex = prevState.findIndex(
        item => item.position === teamElementPosition
      );

      const formateDate = (date: Date) =>
        new Intl.DateTimeFormat('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }).format(date) as unknown as Date;

      const newTeam: ITeamWithPosition = {
        position: teamElementPosition,
        team: {
          id: savedTeam?.id,
          isIndividualSport: isIndividualSport ?? false,
          leagueId: leagueId ?? '',
          leagueName: leagueName ?? '',
          teamId: teamId ?? '',
          teamName: teamName ?? '',
          endDateInTeam: endDateInTeam || formateDate(new Date()),
          startDateInTeam: startDateInTeam || formateDate(new Date()),
        },
      };

      if (existingIndex !== -1) {
        return prevState.map((item, index) =>
          index === existingIndex ? newTeam : item
        );
      } else {
        return [...prevState, newTeam];
      }
    });
  };

  const handleFieldChange = (field: keyof TeamFormInputs, value: any) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <Box gridColumn="span 2">
      <Divider sx={{ m: '36px 0px' }} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb="28px"
        mt="-18px"
      >
        <Typography variant={'h3'}>
          {isIndividualSport ? 'Clubs' : 'Teams'}
        </Typography>

        {teamWithPosition.length > 1 && (
          <IconButton onClick={() => deleteTeam(teamElementPosition)}>
            <DeleteFileRedIcon />
          </IconButton>
        )}
      </Stack>
      <Grid
        container
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        columnGap="16px"
        rowGap="28px"
      >
        <Grid item>
          <Leagues
            typedLeague={typedLeague}
            setTypedLeague={setTypedLeague}
            setTypedTeam={setTypedTeam}
            leagues={leagues}
            isLoading={isLeagueLoading}
            isErrors={isLeagueErrors}
            control={control}
            setFormValue={setValue}
            formStateErrors={errors}
            disabled={disabled}
            setSelectedLeague={league => setSelectedLeague(league)}
            handleFieldChange={handleFieldChange}
            isIndividualSport={isIndividualSport}
            savedTeam={savedTeam}
          />
        </Grid>
        <Grid item>
          <Teams
            typedTeam={typedTeam}
            setTypedTeam={setTypedTeam}
            teams={teams}
            isLoading={isTeamLoading}
            isErrors={isTeamError}
            control={control}
            setFormValue={setValue}
            disabled={!watch('leagueId') && !selectedLeague && !typedLeague}
            formStateErrors={errors}
            leagueId={watch().leagueId}
            handleFieldChange={handleFieldChange}
            isIndividualSport={isIndividualSport}
            savedTeam={savedTeam}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
            }}
          >
            Timeline:
          </Typography>
          <Grid
            item
            container
            display="grid"
            gridTemplateColumns="1fr 1fr"
            columnGap="22px"
          >
            <DateInput<TeamFormInputs>
              control={control}
              name="startDateInTeam"
              error={errors.startDateInTeam as FieldError}
              helperText={errors.startDateInTeam?.message}
              placeholder="Your start date"
              onChange={value => handleFieldChange('startDateInTeam', value)}
              disabled={disabled}
            />

            <DateInput<TeamFormInputs>
              control={control}
              name="endDateInTeam"
              error={errors.endDateInTeam as FieldError}
              helperText={errors.endDateInTeam?.message}
              placeholder={'Your end date'}
              onChange={value => handleFieldChange('endDateInTeam', value)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Box>
  );
};

export default SportBackgroundTeamsBlock;
