import { Box, BoxProps, Skeleton, Typography } from '@mui/material';

interface UserItemSkeletonProps extends BoxProps {}

const UserItemSkeleton = (props: UserItemSkeletonProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      padding="22px 36px"
      border="1px solid #e0e0e0"
      borderRadius={2}
      {...props}
    >
      {/* Avatar Skeleton */}
      <Skeleton variant="circular" width={64} height={64} />

      <Box marginLeft={2} flex={1}>
        {/* FirstName LastName Skeleton */}
        <Typography variant="h6" fontSize={18}>
          <Skeleton width="60%" />
        </Typography>

        {/* Some text Skeleton */}
        <Typography variant="body2" fontSize={14}>
          <Skeleton width="80%" />
        </Typography>
      </Box>
    </Box>
  );
};

export default UserItemSkeleton;
