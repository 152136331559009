import { Box, IconButton, Stack, SxProps } from '@mui/material';
import React, { FC, useMemo } from 'react';
import EditIcon from '../../Icons/EditIcon';
import EyeIcon from '../../Icons/EyeIcon';
import PlayIcon from '../../Icons/PlayIcon';
import TrashIcon from '../../Icons/TrashIcon';
import IconRoundBackground from '../../MUIComponents/IconRoundBackground';

interface ButtonsOverlayProps {
  boxSx?: SxProps;
  isVideo?: boolean;
  isEdit?: boolean;
  nameOfClass: string;
  openHandler: () => void;
  removeHandler?: () => void;
  hasRemove?: boolean;
}

const ButtonsOverlay: FC<ButtonsOverlayProps> = ({
  boxSx,
  isVideo = false,
  isEdit = false,
  nameOfClass,
  openHandler,
  removeHandler,
  hasRemove = true,
}) => {
  const { actionIcon, deleteIcon } = useMemo(() => {
    let actionIcon: JSX.Element = (
      <EyeIcon
        sx={{
          width: 24,
          height: 24,
          fill: 'none',
          path: {
            stroke: 'white',
          },
        }}
      />
    );
    const deleteIcon: JSX.Element = <TrashIcon />;

    if (isEdit) {
      actionIcon = (
        <EditIcon
          sx={{
            path: {
              stroke: 'white',
            },
          }}
        />
      );
    }
    if (isVideo) {
      actionIcon = <PlayIcon width={24} height={24} />;
    }

    return { actionIcon, deleteIcon };
  }, [isEdit, isVideo]);

  return (
    <Box
      className={nameOfClass}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        bgcolor: 'rgba(0, 0, 0, 0.2)',
        display: { xs: 'none', md: 'flex' },
        flexDirection: { xs: 'inherit', md: 'column' },
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity 0.3s',

        ...boxSx,
      }}
    >
      <Stack direction="row">
        <IconButton
          onClick={event => {
            event.stopPropagation();
            openHandler();
          }}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            p: 0,
          }}
        >
          <IconRoundBackground
            width={40}
            height={40}
            backgroundColor="secondary.main"
          >
            {actionIcon}
          </IconRoundBackground>
        </IconButton>
        {hasRemove && (
          <IconButton
            onClick={event => {
              event.stopPropagation();
              removeHandler?.();
            }}
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              p: 0,
            }}
          >
            <IconRoundBackground
              width={40}
              height={40}
              backgroundColor="secondary.main"
            >
              {deleteIcon}
            </IconRoundBackground>
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export default ButtonsOverlay;
