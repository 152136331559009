import { Box, Grid, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { colors } from '../../../../theme/theme';
import ProfilePhotos from './Photos';
import ProfileVideos from './Videos';
import { useState } from 'react';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';

interface HomeLeftBarProps {
  styles?: SxProps;
  cardData: JSX.Element;
  navigatePhotos: () => void;
  navigateVideos: () => void;
}
const HomeLeftBar = ({
  styles,
  cardData,
  navigatePhotos,
  navigateVideos,
}: HomeLeftBarProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  return (
    <Box position="relative" width={{ xs: '100%', lg: 252, xl: 336 }}>
      <Box
        className="home-info-page-leftbar"
        sx={
          !mobileView
            ? {
                mt: '-190px',
                color: '#142237',
                width: { lg: 236, xl: 314 },
                height: 'fit-content',
                padding: { xl: '36px 0 22px' },
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
                ...styles,
              }
            : { ...styles }
        }
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          rowGap={{ lg: '16px', xl: '22px' }}
          sx={{
            backgroundColor: theme.palette.background.default,
            '& .MuiGrid-item': {
              width: '100%',
            },
          }}
        >
          <Grid
            p={{ lg: '28px 16px 16px', xl: '16px 22px' }}
            item
            className="home-edit-box"
            sx={{ backgroundColor: theme.palette.background.white }}
          >
            {cardData}
          </Grid>

          {!mobileView && (
            <>
              <Grid
                p={{ lg: '16px' }}
                item
                className="home-photos-box"
                sx={{
                  backgroundColor: theme.palette.background.white,
                  '& .MuiTypography-root': {
                    fontFamily: 'ES Klarheit Kurrent TRIAL',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '800',
                    lineHeight: '12px',
                    letterSpacing: '0.48px',
                  },
                }}
              >
                <ProfilePhotos
                  navigatePhotos={navigatePhotos}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarSeverity={setSnackbarSeverity}
                />
              </Grid>
              <Grid
                p={{ lg: '16px' }}
                item
                className="home-video-box"
                sx={{
                  backgroundColor: theme.palette.background.white,
                  '& .MuiTypography-root': {
                    fontFamily: 'ES Klarheit Kurrent TRIAL',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '800',
                    lineHeight: '12px',
                    letterSpacing: '0.48px',
                  },
                  color: colors.fontDark,
                }}
              >
                <ProfileVideos
                  navigateVideos={navigateVideos}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbarSeverity={setSnackbarSeverity}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default HomeLeftBar;
