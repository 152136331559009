import { Box, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ListToolBar from '../../../../../components/ListToolbar/ListToolBar';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { GetAllPartnershipsRes } from '../../../../../interfaces/partnership.interface';
import { useGetAllPartershipsQuery } from '../../../../../store/apiSlice/partnershipApi';
import UserItemSkeleton from '../../../shared-components/user-list/skeletons/UserItemSkeleton';
import RosterList from './components/RosterList';

const sortMenuLabel = 'Sort by:';

const sortMenuItems = [
  { label: 'Country', value: 'country' },
  { label: 'Experience', value: 'experience' },
  { label: 'Sport', value: 'sport' },
];

const RosterTab = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const pageParam = parseInt(searchParams.get('page') || '1', 10);
  const limit = parseInt(searchParams.get('limit') || '10', 10);
  const search = searchParams.get('query') || '';
  const sort = searchParams.get('sortBy') || 'desc';
  const sortType = searchParams.get('sortType') || '';

  const [page, setPage] = useState<number>(pageParam);
  const [partnerships, setPartnerships] = useState<
    GetAllPartnershipsRes['partnerships']
  >([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const {
    data: partnershipsData,
    isLoading,
    isFetching,
  } = useGetAllPartershipsQuery(
    {
      page,
      limit,
      search,
      sort,
      sortType,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (partnershipsData) {
      const newPartnerShips = partnershipsData.partnerships || [];
      const total = partnershipsData.pagination.totalCount || 0;

      const count = total - partnerships.length;
      const newItemsToAdd = newPartnerShips.slice(-count);

      if (count > 0) {
        setPartnerships(prevPartnerships =>
          page === 1 ? newPartnerShips : [...prevPartnerships, ...newItemsToAdd]
        );
        const hasNext = total !== 0 && page * limit < total;
        setHasMore(hasNext);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipsData]);

  const handleNextPage = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const loadingSkeleton = useMemo(
    () =>
      Array.from({ length: 5 }).map((_, index) => (
        <UserItemSkeleton key={index} />
      )),
    []
  );

  return (
    <Box bgcolor={theme.palette.background.white}>
      <ListToolBar
        subTitle={
          (partnershipsData?.pagination?.totalCount ?? 0) + ' users in roster'
        }
        sortMenuLabel={sortMenuLabel}
        sortMenuItems={sortMenuItems}
        queryParam={'sortType'}
        needDefaultSortIcon
        sx={{ p: '36px' }}
      />
      <RosterList partnerships={partnerships} isLoading={isLoading} />

      {partnerships.length !== 0 && isFetching && loadingSkeleton}

      {hasMore && (
        <Box>
          <CustomButton
            sx={{
              height: 50,
              width: '100%',
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={handleNextPage}
            disabled={isFetching}
          >
            show more
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default RosterTab;
