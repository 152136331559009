import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { ISportResponse } from '../../../../../interfaces/sport.interface';
import { useGetPaginatedManualSportsQuery } from '../../../../../store/apiSlice/sportApi';
import { SportBackgroundFormInputs } from '../SportBackgroundItem/SportBackgroundItem';

interface SportProps {
  control: Control<SportBackgroundFormInputs, any>;
  setFormValue: UseFormSetValue<SportBackgroundFormInputs>;
  formStateErrors: FieldErrors<SportBackgroundFormInputs>;
  disabled: boolean;
  handleFieldChange: (
    field: keyof SportBackgroundFormInputs,
    value: any
  ) => void;
}

const Sport = ({
  control,
  setFormValue,
  formStateErrors,
  disabled,
  handleFieldChange,
}: SportProps) => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [sports, setSports] = useState<ISportResponse[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearch(search), 300);
    return () => clearTimeout(handler);
  }, [search]);

  const { data, isLoading } = useGetPaginatedManualSportsQuery({
    page: 1,
    limit: 10,
    search: debouncedSearch,
    sort: 'desc',
  });

  useEffect(() => {
    if (data?.data) {
      setSports(data.data as ISportResponse[]);
    }
  }, [data]);

  const handleSelectSport = (sport: ISportResponse) => {
    setSearch(sport.name);
    handleFieldChange('sportName', sport.name);
    handleFieldChange('sportId', sport.id);
    setIsDropdownOpen(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setSearch(value);
    handleFieldChange('sportName', value);
    handleFieldChange('sportId', null);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      if (!sports.some(s => s.name === search)) {
        handleFieldChange('sportName', search);
        handleFieldChange('sportId', null);
      }
      setIsDropdownOpen(false);
    }, 200);
  };

  return (
    <Controller
      name="sportName"
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
          variant="outlined"
          fullWidth
          error={!!formStateErrors.sportName}
        >
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
              color: field.disabled ? 'text.disabled' : 'text.primary',
            }}
          >
            Sport:
          </Typography>

          <TextField
            placeholder="Search and select a sport"
            value={search}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              field.onChange(e);
              handleInputChange(e);
            }}
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={handleInputBlur}
            error={!!formStateErrors.sportName}
            disabled={disabled}
            InputProps={{
              endAdornment: isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null,
            }}
          />

          {isDropdownOpen && sports.length > 0 && (
            <Box
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                bgcolor: 'background.paper',
                border: 'none',
                borderRadius: '4px',
                maxHeight: '200px',
                overflowY: 'auto',
                zIndex: 10,
              }}
            >
              <List>
                {sports.map(sport => (
                  <ListItem
                    key={sport.id}
                    button
                    onMouseDown={() => handleSelectSport(sport)}
                    sx={{
                      padding: '10px 16px',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                    }}
                  >
                    <Typography
                      variant={'caption'}
                      sx={{ fontSize: '14px', fontWeight: 400 }}
                    >
                      {sport.name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </FormControl>
      )}
    />
  );
};

export default Sport;
