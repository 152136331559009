import { Box, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ListToolBar from '../../../../../components/ListToolbar/ListToolBar';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { GetAllApplicantsRes } from '../../../../../interfaces/user.interface';
import { useGetAllApplicantsQuery } from '../../../../../store/apiSlice/userApi';
import UserItemSkeleton from '../../../shared-components/user-list/skeletons/UserItemSkeleton';
import ApplicantsList from './components/ApplicantsList';

const sortMenuLabel = 'Sort by:';

const sortMenuItems = [
  { label: 'Country', value: 'country' },
  { label: 'Experience', value: 'experience' },
  { label: 'Sport', value: 'sport' },
];

const ApplicantsListTab = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const pageParam = parseInt(searchParams.get('page') || '1', 10);
  const limit = parseInt(searchParams.get('limit') || '10', 10);
  const search = searchParams.get('query') || '';
  const sort = searchParams.get('sortBy') || 'desc';
  const sortType = searchParams.get('sortType') || '';

  const [page, setPage] = useState<number>(pageParam);
  const [applicants, setApplicants] = useState<GetAllApplicantsRes['data']>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const {
    data: applicantsData,
    isLoading,
    isFetching,
  } = useGetAllApplicantsQuery(
    {
      page,
      limit,
      search,
      sort,
      sortType,
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (applicantsData) {
      const newApplicants = applicantsData.data || [];
      const total = applicantsData.total || 0;

      const count = total - applicants.length;
      const newItemsToAdd = newApplicants.slice(-count);

      if (count > 0) {
        setApplicants(prevApplicants =>
          page === 1 ? newApplicants : [...prevApplicants, ...newItemsToAdd]
        );
        const hasNext = total !== 0 && page * limit < total;
        setHasMore(hasNext);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantsData]);

  console.log('page', page);

  const handleNextPage = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const loadingSkeleton = useMemo(
    () =>
      Array.from({ length: 5 }).map((_, index) => (
        <UserItemSkeleton key={index} />
      )),
    []
  );

  return (
    <Box bgcolor={theme.palette.background.white}>
      <ListToolBar
        subTitle={(applicantsData?.total ?? 0) + ' applicants'}
        sortMenuLabel={sortMenuLabel}
        sortMenuItems={sortMenuItems}
        queryParam={'sortType'}
        needDefaultSortIcon={true}
        sx={{ p: '36px' }}
      />
      <ApplicantsList applicants={applicants} isLoading={isLoading} />

      {applicants.length !== 0 && isFetching && loadingSkeleton}

      {hasMore && (
        <Box>
          <CustomButton
            sx={{
              height: 50,
              width: '100%',
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={handleNextPage}
            disabled={isFetching}
          >
            show more
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default ApplicantsListTab;
