import { config } from '../../../config/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ILoginRequest,
  ILoginResponse,
  IRegistrationRequest,
  IRegistrationResponse,
  IForgotPasswordRequest,
  IUpdatePasswordRequest,
  IForgotPasswordResponse,
  IUpdatePasswordResponse,
  IResendVerifyCodeResponse,
  IResendVerifyCodeRequest,
} from '../../../interfaces/auth.interface';
import {
  IInviteUsersEmailsRequest,
  IInviteUsersEmailsResponse,
} from '../../../interfaces/user.interface';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../enums/cookie-enum';

const baseQuery = fetchBaseQuery({
  baseUrl: config.BASE_USER_MICROSERVICE_API_URL + '/auth',
});

const baseQueryWithInterceptor = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const response = await baseQuery(args, api, extraOptions);

  if ((response as any)?.error?.status === 401) {
    Cookies.remove(CookieEnum.ACCESS_TOKEN);
  }

  return response;
};

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['auth'],

  endpoints: builder => ({
    registration: builder.mutation<
      IRegistrationResponse,
      {
        inviteToken: string;
        inviteEmail: string;
        registrationData: IRegistrationRequest;
      }
    >({
      query: (data: {
        inviteToken: string;
        inviteEmail: string;
        registrationData: IRegistrationRequest;
      }) => ({
        url: `/registration?inviteEmail=${data.inviteEmail}`,
        method: 'POST',
        body: data.registrationData,
        headers: {
          Authorization: `Bearer ${data.inviteToken}`,
        },
      }),
      invalidatesTags: ['auth'],
    }),
    verifyCode: builder.mutation<
      ILoginResponse,
      { email: string; verificationCode: string; password: string }
    >({
      query: (data: {
        email: string;
        verificationCode: string;
        password: string;
      }) => ({
        url: `/verify-code`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (data: ILoginRequest) => ({
        url: `/login`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
    resendVerifyCode: builder.mutation<
      IResendVerifyCodeResponse,
      IResendVerifyCodeRequest
    >({
      query: (data: IResendVerifyCodeRequest) => ({
        url: `/resend-verify-code`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
    forgotPassword: builder.mutation<
      IForgotPasswordResponse,
      IForgotPasswordRequest
    >({
      query: (data: IForgotPasswordRequest) => ({
        url: `/forgot-password`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),
    updatePassword: builder.mutation<
      IUpdatePasswordResponse,
      IUpdatePasswordRequest
    >({
      query: (data: IUpdatePasswordRequest) => ({
        url: `/update-password`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['auth'],
    }),

    inviteUsersByEmails: builder.mutation<
      IInviteUsersEmailsResponse,
      IInviteUsersEmailsRequest
    >({
      query: (data: IInviteUsersEmailsRequest) => {
        const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
        return {
          url: `/invite-users`,
          method: 'POST',
          body: data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ['auth'],
    }),
    deleteInviteUserByEmail: builder.mutation<
      IInviteUsersEmailsResponse,
      string
    >({
      query: (email: string) => {
        const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
        return {
          url: `/invite-user/email/${email}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ['auth'],
    }),
  }),

  refetchOnReconnect: true,
});

export const {
  useRegistrationMutation,
  useVerifyCodeMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useInviteUsersByEmailsMutation,
  useResendVerifyCodeMutation,
  useDeleteInviteUserByEmailMutation,
  usePrefetch,
} = authApi;

export default authApi;
