import { Box } from '@mui/material';
import GetStarted from '../../../components/Auth/VerifyAccount/GetStarted/GetStarted';
import Header from '../../../components/Header/Header';
import { HeaderTypeEnum } from '../../../enums/header-type';
import Footer from '../../../components/Header/Footer/Footer';

const GetStartedPage = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100vh'}>
      <Header type={HeaderTypeEnum.VERIFY_ACCOUNT} />
      <Box
        display={'flex'}
        flexDirection={'column'}
        pb={{ xs: '2rem', md: 0 }}
        my={'auto'}
      >
        <Box my={{ md: '30px' }}>
          <GetStarted />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default GetStartedPage;
