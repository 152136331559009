import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { PhotoAlbum } from '../../../../../interfaces/media.interface';
import UploadedFileIconElement from '../../../../Auth/Elements/UploadedFileIconElement';
import PhotosIcon from '../../../../Icons/PhotosIcon';
import EmptyMediaBox from '../../../EmptyMediaBox';
import ButtonsOverlay from '../../../Layouts/ButtonsOverlay';

interface PhotoGridProps {
  openAlbum: (album: PhotoAlbum) => void;
  albums: PhotoAlbum[] | undefined;
  openAlbumEdit: (album: PhotoAlbum) => void;
  handleFileRemove: (id: string) => Promise<void>;
}
const PhotoAlbumGrid = ({
  albums,
  openAlbum,
  openAlbumEdit,
  handleFileRemove,
}: PhotoGridProps) => {
  const { isOwnPage } = useCurrentPageUserId();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box sx={{ textAlign: 'center' }}>
      {!albums?.length ? (
        <EmptyMediaBox
          text={`There are no albums yet`}
          icon={
            <UploadedFileIconElement>
              <PhotosIcon active />
            </UploadedFileIconElement>
          }
        />
      ) : (
        <Grid container spacing={2}>
          {albums?.map(album => (
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              xl={2}
              key={album.id}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              overflow="hidden"
              onClick={() => openAlbum(album)}
              sx={{ cursor: 'pointer' }}
            >
              <Box
                position="relative"
                width="100%"
                bgcolor="secondary2.main"
                sx={{
                  paddingBottom: '100%',
                  ...(!mobileView
                    ? { '&:hover .photo-album-buttons-overlay': { opacity: 1 } }
                    : {}),
                }}
              >
                <img
                  src={album.fileUrl}
                  alt="Preview"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
                {isOwnPage && (
                  <ButtonsOverlay
                    isEdit
                    nameOfClass="photo-album-buttons-overlay"
                    openHandler={() => openAlbumEdit(album)}
                    removeHandler={() => handleFileRemove(album.id)}
                    boxSx={mobileView ? { display: 'flex', opacity: 1 } : {}}
                  />
                )}
              </Box>
              <Typography
                variant="h4"
                color="text.primary"
                textAlign="left"
                sx={{ width: '100%' }}
                mt={2}
              >
                {album.name}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                textAlign="left"
                fontWeight={500}
                sx={{ width: '100%' }}
                mt={1}
              >
                {album.photos.length} Photos
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default PhotoAlbumGrid;
