import { Box, Divider, Stack } from '@mui/material';
import {
  createContext,
  ForwardedRef,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form';
import { SportBackgroundFormInputs } from '../../../../components/Auth/VerifyAccount/SportVerification/SportBackgroundItem/SportBackgroundItem';
import { ISportBackgroundWithPosition } from '../../../../components/Auth/VerifyAccount/SportVerification/SportVerification';
import SportVerificationItem from '../../../../components/Auth/VerifyAccount/SportVerification/SportVerificationItem/SportVerificationItem';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import {
  ISportBackground,
  ISportBackgroundFormData,
  ITeam,
  IUserSportBackgroundResponse,
} from '../../../../interfaces/user-sport-background.interface';
import {
  useCreateCareerMutation,
  useDeleteCareerMutation,
  useUpdateCareerMutation,
} from '../../../../store/apiSlice/careerApi';
import { useUpdateUserSportBackgroundMutation } from '../../../../store/apiSlice/sportBackgroundApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { createTeamSchema } from '../../../../validation/sport-validation';

export const SportBackgroundContext =
  createContext<SportBackgroundFormInputs | null>(null);

interface EditSportBackgroundProps {
  sportBg: IUserSportBackgroundResponse;
  openSportBackgroundEditClose: (sportBgId: string) => void;
}

const EditSportBackground = (
  { sportBg, openSportBackgroundEditClose }: EditSportBackgroundProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const sportBgs = useMemo(() => [sportBg], [sportBg]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [updateUserSportBg] = useUpdateUserSportBackgroundMutation();
  const [createCareer] = useCreateCareerMutation();
  const [updateCareer] = useUpdateCareerMutation();
  const [deleteCareer] = useDeleteCareerMutation();
  const [defaultFormState, setDefaultFormState] =
    useState<ISportBackground[]>();
  const [disabled, setDisabled] = useState<boolean>(true);

  const [sportBackGroundWithPosition, setSportBackGroundWithPosition] =
    useState<ISportBackgroundWithPosition[]>([
      { position: 1, sportBackground: null },
    ]);
  const [sportBackGroundsElements, setSportBackGroundsElements] = useState<
    number[]
  >([1]);

  const componentFirstRenderDate = useRef(new Date());

  useEffect(() => {
    // check if form state is changed then undisable save/cancel buttons
    const areSportBackgroundsEqual = (
      oldSportBackgrounds: ISportBackground[],
      newSportBackgrounds: ISportBackground[]
    ) => {
      return (
        JSON.stringify(oldSportBackgrounds) ===
        JSON.stringify(newSportBackgrounds)
      );
    };
    if (!defaultFormState) {
      return setDefaultFormState(
        sportBackGroundWithPosition.map(
          el => el.sportBackground as ISportBackground
        )
      );
    }
    if (
      !areSportBackgroundsEqual(
        defaultFormState,
        sportBackGroundWithPosition.map(
          el => el.sportBackground as ISportBackground
        )
      )
    ) {
      if (
        componentFirstRenderDate.current &&
        new Date().getTime() - componentFirstRenderDate.current.getTime() > 3000
      ) {
        setDisabled(false);
      }
    }
  }, [sportBackGroundWithPosition, defaultFormState]);

  useEffect(() => {
    if (sportBgs) {
      setSportBackGroundWithPosition(
        sportBgs?.map((sportBg, index) => {
          return {
            position: index + 1,
            sportBackground: {
              id: sportBg.id,
              sportId: sportBg.sportId,
              startCareerDate: new Date(sportBg.startCareerDate),
              endCareerDate: new Date(sportBg.endCareerDate),
            },
          };
        })
      );
    }
  }, [sportBgs]);

  useEffect(() => {
    if (sportBackGroundsElements?.length <= 0) {
      setSportBackGroundsElements([1]);
    }
  }, [sportBackGroundsElements]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const deleteSportBackground = (value: number) => {
    setSportBackGroundsElements(prev =>
      prev.filter(backgroundValue => backgroundValue !== value)
    );
    setSportBackGroundWithPosition(prev =>
      prev.filter(sportBackGround => sportBackGround.position !== value)
    );
  };

  const sportBgFormDefault = useCallback(
    (
      sportBackground: IUserSportBackgroundResponse
    ): SportBackgroundFormInputs => {
      const teams = sportBackground.careers.map(career => ({
        id: career.id,
        isIndividualSport: career.isIndividualSport,
        leagueId: career.idLeague,
        leagueName: career.leagueName,
        teamId: career.idTeam,
        teamName: career.teamName,
        startDateInTeam: new Date(career.startDate),
        endDateInTeam: new Date(career.endDate),
      }));

      return {
        id: sportBackground.id,
        isOtherSport: false,
        startCareerDate: new Date(sportBackground.startCareerDate),
        endCareerDate: new Date(sportBackground.endCareerDate),
        description: sportBackground.description,
        isIndividualSport: sportBackground.isIndividual,
        teams: teams,
      };
    },
    []
  );

  const onSubmitSport: SubmitHandler<ISportBackgroundFormData> = useCallback(
    async data => {
      // Validate teams form
      try {
        for (const sportBackground of data.sportBackgrounds) {
          for (const team of sportBackground.teams || []) {
            try {
              await createTeamSchema(
                (sportBackground.isOtherSport || team.isIndividualSport) ??
                  false
              ).validate(team, {
                abortEarly: false,
              });
              // If valid, proceed with submission
            } catch (validationErrors: any) {
              const errorMessages: any[] = validationErrors.inner.map(
                (error: any) => ({
                  path: error.path, // The field with the error
                  message: error.message, // The error message
                })
              );

              const errorToString = errorMessages.reduce<string>(
                (acc, cur) => acc + `${cur.message}\n`,
                ''
              );

              throw new Error(errorToString); // Throw the error to propagate it to the outer catch block
            }
          }
        }
        // Continue with the next code if all validations pass
      } catch (error) {
        // Handle the error from any team validation
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);

        return;
      }

      const sportIds = [] as string[];
      const uniqueSportIds = new Set();

      data.sportBackgrounds.forEach(sportBackground => {
        if (!uniqueSportIds.has(sportBackground.sportId)) {
          uniqueSportIds.add(sportBackground.sportId);
          if (sportBackground.sportId.length > 0) {
            sportIds.push(sportBackground.sportId);
          }
        }
      });

      if (sportIds.length === 0) {
        setSnackbarMessage('Please select at least one sport.');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
        return;
      }

      try {
        await Promise.all(
          data.sportBackgrounds.map(async sportBackground => {
            const sportBackgroundData = new FormData();
            sportBackgroundData.append('sportId', sportBackground.sportId);
            if (sportBackground.description) {
              sportBackgroundData.append(
                'description',
                sportBackground.description
              );
            }
            if (sportBackground.startCareerDate) {
              sportBackgroundData.append(
                'startCareerDate',
                new Date(sportBackground.startCareerDate).toISOString()
              );
            }
            if (sportBackground.endCareerDate) {
              sportBackgroundData.append(
                'endCareerDate',
                new Date(sportBackground.endCareerDate).toISOString()
              );
            }

            if (!sportBackground.id) {
              throw new Error(
                'Sport background must have an ID to be updated.'
              );
            }

            const updatedSportBg = await updateUserSportBg({
              id: sportBackground.id,
              formData: sportBackgroundData,
            }).unwrap();

            if (sportBackground.teams?.length) {
              await Promise.all(
                sportBackground.teams.map(async team => {
                  if (!team.id) {
                    return await createCareer({
                      sportId: sportBackground.sportId,
                      userSportBackgroundId: updatedSportBg.id,
                      idLeague: team.leagueId ?? '',
                      idTeam: team.teamId ?? '',
                      leagueName: team.leagueName ?? '',
                      teamName: team.teamName ?? '',
                      startDate: new Date(team.startDateInTeam).toISOString(),
                      endDate: new Date(team.endDateInTeam).toISOString(),
                    });
                  }

                  return await updateCareer({
                    id: team.id,
                    data: {
                      userSportBackgroundId: updatedSportBg.id,
                      idLeague: team.leagueId ?? '',
                      idTeam: team.teamId ?? '',
                      leagueName: team.leagueName ?? '',
                      teamName: team.teamName ?? '',
                      startDate: new Date(team.startDateInTeam).toISOString(),
                      endDate: new Date(team.endDateInTeam).toISOString(),
                    },
                  });
                })
              );

              // delete teams if any was deleted
              const currentSportBgTeams = sportBgs?.find(
                item => item.id === sportBackground.id
              )?.careers;

              if (!currentSportBgTeams?.length) {
                setSnackbarMessage('There are no teams to update.');
                setSnackbarSeverity(SnackbarSeverityEnum.INFO);
                setSnackbarOpen(true);

                return;
              }
              const sportBgTeamsUpdated = sportBackground.teams as ITeam[];
              const teamsToDelete = currentSportBgTeams?.filter(
                currentTeam =>
                  sportBgTeamsUpdated.findIndex(
                    updatedTeam => updatedTeam.id === currentTeam.id
                  ) < 0
              );
              await Promise.all(
                teamsToDelete.map(item => deleteCareer(item.id))
              );
            }
          })
        );
        setDisabled(true);
        setSnackbarMessage('Sport background successfully edited.');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setSnackbarOpen(true);
      } catch (error: any) {
        const errorMessage = errorHelper(error);
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      }
      setTimeout(() => {
        openSportBackgroundEditClose(sportBg.id);
      }, 1700);
    },
    [
      sportBg.id,
      sportBgs,
      createCareer,
      updateCareer,
      deleteCareer,
      updateUserSportBg,
      openSportBackgroundEditClose,
    ]
  );

  return (
    <div ref={ref}>
      {sportBgs?.map((sportBg, index) => {
        return (
          <Fragment key={sportBg.id}>
            <Box>
              <SportBackgroundContext.Provider
                value={sportBgFormDefault(sportBg)}
              >
                <SportVerificationItem
                  isEdit
                  sportBackground={sportBg}
                  sportBackgroundElementPosition={index + 1}
                  sportBackGroundWithPosition={sportBackGroundWithPosition}
                  setSportBackGroundWithPosition={
                    setSportBackGroundWithPosition
                  }
                  deleteSportBackground={deleteSportBackground}
                />
              </SportBackgroundContext.Provider>
            </Box>
            <Divider sx={{ m: '48px 0 35px' }} />
          </Fragment>
        );
      })}

      <Stack direction="row" columnGap="16px">
        <CustomButton
          onClick={() =>
            onSubmitSport({
              sportBackgrounds: sportBackGroundWithPosition.map(
                item => item.sportBackground as ISportBackground
              ),
            })
          }
          variant="outlined"
          buttonWidth={220}
          disabled={disabled}
        >
          save
        </CustomButton>
        <CustomButton
          onClick={() => openSportBackgroundEditClose(sportBg.id)}
          variant="outlined"
          buttonWidth={220}
          variantType={ButtonTypeEnum.SECONDARY}
        >
          cancel
        </CustomButton>
      </Stack>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default forwardRef(EditSportBackground);
