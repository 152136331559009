import { Box, BoxProps } from '@mui/material';
import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface ISidebarLayoutProps extends BoxProps {
  sidebar: ReactNode;
  children?: ReactNode;
}

const SidebarLayout = ({
  sidebar,
  children,
  ...props
}: ISidebarLayoutProps) => {
  return (
    <Box
      display="flex"
      gap={{ lg: '16px', xl: '22px' }}
      maxWidth={{
        xl: "1320px"
      }}
      width="100%"
      {...props}
    >
      {sidebar}
      {children || <Outlet />}
    </Box>
  );
};

export default SidebarLayout;
