import React, { useState } from 'react';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import SidebarLayout from '../../layouts/SidebarLayout';
import { Box, useMediaQuery } from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import SearchResults from './components/SearchResults/SearchResults';
import { useSearchParams } from 'react-router-dom';
import GlobalSearchSidebar, {
  SEARCH_TYPE,
} from './components/GlobalSearchSidebar/GlobalSearchSidebar';

const GlobalSearchPage = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [searchParams] = useSearchParams();

  const [radioValue, setRadioValue] = useState<SEARCH_TYPE>(
    searchParams.get('search-type') === 'post' ? 'POSTS' : 'PEOPLE'
  );

  const handleChangeSearchType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRadioValue(event.target.value as SEARCH_TYPE);
  };

  return (
    <SidebarLayout
      sx={{
        maxWidth: {
          lg: 'calc(1440px - 32px)',
          xl: 'calc(1440px - 120px)',
        },
        marginX: 'auto',
      }}
      sidebar={
        <GlobalSearchSidebar
          radioValue={radioValue}
          handleChange={handleChangeSearchType}
        />
      }
      flexDirection={matches ? 'row' : 'column'}
    >
      <SectionLayout
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          bgcolor: theme.palette.background.white,
        }}
      >
        <SearchResults isPeople={radioValue === 'PEOPLE'} />
      </SectionLayout>

      <Box minWidth={{ xl: '19.75rem' }} />
    </SidebarLayout>
  );
};

export default GlobalSearchPage;
