import { Dispatch, FC, SetStateAction, useState } from 'react';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useModalManager } from '../../../../../hooks/useModalManager';
import { Video, VideoAlbum } from '../../../../../interfaces/media.interface';
import {
  useDeleteVideoAlbumMutation,
  useGetAllUserVideoAlbumsByIdQuery,
  useGetAllUserVideoAlbumsQuery,
} from '../../../../../store/apiSlice/profileMediaApi';
import GridSkeleton from '../../../GridSkeleton';
import ChooseCoverImageModal from '../../../Modals/ChooseCoverImageModal';
import EditAlbumModal from '../modals/EditAlbumModal';
import VideoAlbumGrid from './VideoAlbumGrid';

enum VideoAlbumAreaModals {
  EDIT_ALBUM_MODAL = 'EDIT_ALBUM_MODAL',
  CHOOSE_COVER_IMAGE_MODAL = 'CHOOSE_COVER_IMAGE_MODAL',
}

interface VideoAlbumAreaProps {
  album: VideoAlbum | null;
  setAlbum: (album: VideoAlbum | null) => void;
  openAlbum: () => void;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
  setIsAlbumEditing: Dispatch<SetStateAction<boolean>>;
}

const VideoAlbumArea: FC<VideoAlbumAreaProps> = ({
  album,
  setAlbum,
  openAlbum,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setIsAlbumEditing,
}) => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const { currentModal, toggleModal } = useModalManager();
  const [newCoverImage, setNewCoverImage] = useState<Video | null>(null);

  const { data: videoAlbumData, isLoading: isLoadingOwnPage } =
    useGetAllUserVideoAlbumsQuery(undefined, {
      skip: !isOwnPage,
    });

  const { data: userVideoAlbumData, isLoading: isLoadingOtherPage } =
    useGetAllUserVideoAlbumsByIdQuery(userId || '', {
      skip: isOwnPage,
    });

  const albums = isOwnPage ? videoAlbumData : userVideoAlbumData;
  const isLoading = isLoadingOwnPage || isLoadingOtherPage;

  const [deleteVideoAlbum] = useDeleteVideoAlbumMutation();

  const handleFileRemove = async (id: string) => {
    await deleteVideoAlbum(id);
    setSnackbarMessage('Video Album deleted successfully');
    setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    setSnackbarOpen(true);
  };

  const handleOpenAlbum = (album: VideoAlbum) => {
    setAlbum(album);
    openAlbum();
  };

  const openEditAlbumModal = (album: VideoAlbum) => {
    setAlbum(album);
    setIsAlbumEditing(true);
    toggleModal(VideoAlbumAreaModals.EDIT_ALBUM_MODAL);
  };

  const closeEditAlbumModal = () => {
    setAlbum(null);
    setIsAlbumEditing(false);
    toggleModal(VideoAlbumAreaModals.EDIT_ALBUM_MODAL);
  };

  const openChooseCoverImageModal = () =>
    toggleModal(VideoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL);

  const closeChooseCoverImageModal = () =>
    toggleModal(VideoAlbumAreaModals.EDIT_ALBUM_MODAL);

  return (
    <>
      {isLoading ? (
        <GridSkeleton xs={6} sm={4} lg={3} xl={2} />
      ) : (
        <>
          {album && (
            <>
              <EditAlbumModal
                album={album}
                title="Edit playlist"
                newCoverImage={newCoverImage}
                isOpen={
                  currentModal === VideoAlbumAreaModals.EDIT_ALBUM_MODAL ||
                  currentModal === VideoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL
                }
                onClose={closeEditAlbumModal}
                openChooseCoverImageModal={openChooseCoverImageModal}
              />
              <ChooseCoverImageModal
                albumId={album.id}
                mediaType="video"
                selectNewCoverImage={img => setNewCoverImage(img as Video)}
                title="Choose a cover image"
                isOpen={
                  currentModal === VideoAlbumAreaModals.CHOOSE_COVER_IMAGE_MODAL
                }
                onClose={closeChooseCoverImageModal}
              />
            </>
          )}
          <VideoAlbumGrid
            openAlbum={handleOpenAlbum}
            albums={albums}
            handleFileRemove={handleFileRemove}
            openAlbumEdit={openEditAlbumModal}
          />
        </>
      )}
    </>
  );
};

export default VideoAlbumArea;
