import React, { FC, useState } from 'react';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedChat,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import {
  IGroupChat,
  useRemoveGroupChatMemberMutation,
} from '../../../../store/apiSlice/chat/groupApi';
import EditChatIcon from '../../../../components/Icons/EditChatIcon';
import Edit from '../ChatHeader/ChatActions/Edit';
import { useModal } from '../../../../hooks/useModal';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import CloseX from '../../../../components/Icons/CloseX';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import InvitationForm from './InvitationForm';

interface GroupInfoProps {}

const GroupInfo: FC<GroupInfoProps> = () => {
  const { theme } = useThemeContext();
  const { toggleModal, closeModal, isOpen, openModal } = useModal();

  const authData = useSelector(selectAuthData);
  const selectedChat = useSelector(getSelectedChat)! as IGroupChat;

  const [isInvitationWindowOpen, setIsInvitationWindowOpen] =
    useState<boolean>(false);
  const [removeGroupChatMember] = useRemoveGroupChatMemberMutation();
  const dispatch = useDispatch();

  const removeMemberFromChat = async (memberId: string) => {
    const result = await removeGroupChatMember({
      chatId: selectedChat.id,
      userId: memberId,
    });

    if (!result.error) {
      const updatedMembers = selectedChat.members.filter(
        member => member.id !== memberId
      );

      dispatch(
        setSelectedChat({
          ...selectedChat,
          members: updatedMembers,
        })
      );
    }
  };

  const editModal = (
    <BaseModal
      isOpen={isOpen}
      toggle={toggleModal}
      disableContentMargin
      disableDefaultFooter
      disableEscapeButton
      boxSx={{
        p: '2.25rem',
        width: 'fit-content',
      }}
    >
      <Box
        onClick={closeModal}
        sx={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <IconWithBackground size={'2.5rem'} icon={<CloseX />} />
      </Box>
      <Box
        textAlign={'center'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '2.25rem',
          mx: 'none',
          maxWidth: 'inherit',
          textAlign: 'inherit',
        }}
      >
        <Edit onClose={closeModal} />
      </Box>
    </BaseModal>
  );

  if (isInvitationWindowOpen) {
    return <InvitationForm onClose={() => setIsInvitationWindowOpen(false)} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2.25rem',
        padding: '2.25rem',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.375rem',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '0.75rem',
          }}
        >
          <Avatar
            sx={{ width: '2.5rem', height: '2.5rem' }}
            src={
              selectedChat.thumb
                ? `data:image/jpeg;base64, ${selectedChat.thumb}`
                : ''
            }
          />
          <Typography typography={'h2'} sx={{ width: '100%' }}>
            {selectedChat.name}
          </Typography>
          {selectedChat.userId === authData.user.id && (
            <IconButton
              onClick={() => {
                openModal();
              }}
              sx={{
                width: '1.75rem',
                height: '1.75rem',
              }}
            >
              <EditChatIcon />
            </IconButton>
          )}
          {editModal}
        </Box>
        <Box>
          <Typography typography={'body2'}>
            {selectedChat.description}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: theme.palette.background.lightDark,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2.25rem',
          overflowY: 'auto',
          scrollbarWidth: 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '0.75rem',
          }}
        >
          <Typography variant={'h3'}>{'Chat members'}</Typography>
          <CustomButton
            variant={'contained'}
            onClick={() => setIsInvitationWindowOpen(true)}
            fontSize={'0.625rem'}
            sx={{
              height: 'auto',
              padding: '1rem',
            }}
          >
            Invite people
          </CustomButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.375rem',
          }}
        >
          {selectedChat.members.map((member, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Avatar
                  sx={{ width: '4rem', height: '4rem' }}
                  src={
                    member.thumbnail
                      ? `data:image/jpeg;base64, ${member.thumbnail}`
                      : ''
                  }
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.375rem',
                  }}
                >
                  <Typography variant={'body1'}>
                    {`${member.firstName} ${member.lastName}`}
                  </Typography>
                  <Typography variant={'body3'}>
                    {member.sports.map(sport => sport.name).join(', ')}
                  </Typography>
                </Box>
              </Box>
              {selectedChat.userId === authData.user.id &&
                selectedChat.userId !== member.id && (
                  <CustomButton
                    variantType={ButtonTypeEnum.SECONDARY}
                    onClick={() => removeMemberFromChat(member.id)}
                    fontSize={'0.625rem'}
                    sx={{
                      minWidth: '7.125rem',
                      height: 'auto',
                      padding: '1rem',
                    }}
                  >
                    {'remove'}
                  </CustomButton>
                )}
              {selectedChat.userId === member.id && (
                <Typography
                  variant={'body4'}
                  sx={{
                    textAlign: 'center',
                    minWidth: '7.125rem',
                    height: 'auto',
                    padding: '1rem',
                  }}
                >
                  {'Owner'}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default GroupInfo;
