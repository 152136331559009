import React, { useCallback, useEffect } from 'react';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import {
  ILeague,
  ILeagueBySportNameResponse,
} from '../../../../../interfaces/career.interface';
import ArrowDownIcon from '../../../../Icons/ArrowDownIcon';
import AutocompleteOption from '../../../../shared/AutocompleteOption/AutocompleteOption';
import { TeamFormInputs } from '../SportBackgroundItem/SportBackgroundItem';

interface LeaguesProps {
  typedLeague: string;
  setTypedLeague: (league: string) => void;
  setTypedTeam: (team: string) => void;
  leagues: ILeagueBySportNameResponse[];
  isLoading: boolean;
  isErrors: boolean;
  control: Control<TeamFormInputs, any>;
  setFormValue: UseFormSetValue<TeamFormInputs>;
  formStateErrors: FieldErrors<TeamFormInputs>;
  disabled: boolean;
  setSelectedLeague: (league: ILeague | null) => void;
  handleFieldChange: (field: keyof TeamFormInputs, value: any) => void;
  isIndividualSport: boolean;
  savedTeam?: TeamFormInputs;
}

const Leagues = ({
  typedLeague,
  setTypedLeague,
  setTypedTeam,
  leagues,
  isLoading,
  isErrors,
  control,
  setFormValue,
  formStateErrors,
  disabled,
  setSelectedLeague,
  handleFieldChange,
  isIndividualSport,
  savedTeam,
}: LeaguesProps) => {
  useEffect(() => {
    if (savedTeam) {
      setTypedLeague(savedTeam.leagueName || '');
      handleFieldChange('leagueId', savedTeam?.leagueId);
      handleFieldChange('leagueName', savedTeam?.leagueName);
    }
  }, [savedTeam?.id, setTypedLeague]);

  const leagueValue = useCallback(
    (field: ControllerRenderProps<TeamFormInputs, 'leagueId'>) => {
      return leagues.find(el => el.idLeague === field.value) || null;
    },
    [leagues]
  );

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    setTypedLeague(newInputValue);
    handleFieldChange('leagueName', newInputValue);
    handleFieldChange('leagueId', null);
    handleFieldChange('teamId', '');
    handleFieldChange('teamName', '');
    setTypedTeam('');
    setSelectedLeague(null);
  };

  const handleSelectChange = (
    event: React.SyntheticEvent,
    newValue: ILeagueBySportNameResponse | string | null
  ) => {
    if (newValue && typeof newValue === 'object') {
      handleFieldChange('leagueId', newValue.idLeague);
      handleFieldChange('leagueName', newValue.strLeague);
      handleFieldChange('teamName', '');
      setTypedTeam('');
      setTypedLeague(newValue.strLeague);
      setSelectedLeague(newValue);
    } else if (typeof newValue === 'string') {
      handleFieldChange('leagueName', newValue);
      handleFieldChange('leagueId', null);
      setTypedLeague(newValue);
    } else {
      handleFieldChange('leagueId', '');
      handleFieldChange('teamId', '');
      handleFieldChange('teamName', '');
      handleFieldChange('leagueName', '');
      setTypedTeam('');
      setTypedLeague('');
      setFormValue('teamId', '');
      setSelectedLeague(null);
    }
  };

  return (
    <Controller
      name="leagueId"
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="outlined"
          fullWidth
          error={!!formStateErrors.leagueId}
        >
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
              color: field.disabled ? 'text.disabled' : 'text.primary',
            }}
          >
            League:
          </Typography>
          <Autocomplete
            freeSolo
            defaultValue={leagueValue(field)}
            value={leagueValue(field) || typedLeague}
            inputValue={typedLeague}
            onInputChange={handleInputChange}
            loading={isLoading}
            options={leagues ?? []}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.strLeague || ''
            }
            isOptionEqualToValue={(option, value) =>
              typeof value === 'object' && option.idLeague === value?.idLeague
            }
            onChange={handleSelectChange}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search or enter a league"
                error={!!formStateErrors.leagueId}
                helperText={
                  formStateErrors.leagueId
                    ? formStateErrors.leagueId.message
                    : ''
                }
                disabled={isLoading || disabled}
              />
            )}
            renderOption={(props, option) => (
              <AutocompleteOption text={option.strLeague} props={props} />
            )}
            disabled={isLoading || disabled}
            sx={{
              '.MuiInputBase-root': {
                height: '50px',
              },
              '.MuiSvgIcon-root': {
                bgcolor: 'transparent !important',
              },
            }}
            popupIcon={<ArrowDownIcon />}
          />
        </FormControl>
      )}
    />
  );
};

export default Leagues;
