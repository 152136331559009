import { Box, Container } from '@mui/material';
import LiveAgentCard from '../../../components/Profile/LiveAgentProfile/LiveAgentCard';
import ProfileCardContainer from '../../../components/Profile/ProfileCardContainer';
import LiveAgentMainContent from './components/live-agent-main/LiveAgentMainContent';

const LiveAgentInProgressPage = () => {
  return (
    <Container
      disableGutters
      className="live-agent-in-progress-page__container"
      sx={{
        maxWidth: '1320px !important',
      }}
    >
      <Box
        className={'live-agent-in-progress-page__layout-container'}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={'22px'}
        width="100%"
      >
        <ProfileCardContainer
          component="aside"
          className="live-agent-in-progress-page__left-bar"
          cardData={<LiveAgentCard />}
          width={{ lg: 236, xl: 314 }}
        />
        <LiveAgentMainContent
          component="main"
          className="live-agent-in-progress-page__main-content"
          display={'flex'}
          flexDirection={'column'}
          width="100%"
        />
      </Box>
    </Container>
  );
};

export default LiveAgentInProgressPage;
