import { Box, Link } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Logo from '../components/Logo/Logo';
import Footer from '../components/Header/Footer/Footer';

const BlankLayout = () => {
  return (
    <Box
      width={'100%'}
      bgcolor={'background.white'}
      minHeight={'100dvh'}
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      pt={'1rem'}
    >
      <Link href={'/'}>
        <Logo />
      </Link>
      <Box
        display={'flex'}
        justifyContent={'center'}
        marginY={'auto'}
        width="100%"
        bgcolor={'background.white'}
        sx={{
          p: {
            xs: '1rem',
            md: '1rem 30px',
            lg: '1rem 60px',
            sm: '1rem',
          },
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default BlankLayout;
