import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { ITeamsByLeagueNameResponse } from '../../../../../interfaces/career.interface';
import ArrowDownIcon from '../../../../Icons/ArrowDownIcon';
import AutocompleteOption from '../../../../shared/AutocompleteOption/AutocompleteOption';
import { TeamFormInputs } from '../SportBackgroundItem/SportBackgroundItem';

interface TeamsProps {
  typedTeam: string;
  setTypedTeam: (team: string) => void;
  teams: ITeamsByLeagueNameResponse[];
  isLoading: boolean;
  isErrors: boolean;
  control: Control<TeamFormInputs, any>;
  setFormValue: UseFormSetValue<TeamFormInputs>;
  formStateErrors: FieldErrors<TeamFormInputs>;
  disabled: boolean;
  isIndividualSport: boolean;
  leagueId?: string | null;
  handleFieldChange: (field: keyof TeamFormInputs, value: any) => void;
  savedTeam?: TeamFormInputs;
}

const Teams = ({
  typedTeam,
  setTypedTeam,
  teams,
  isLoading,
  isErrors,
  control,
  setFormValue,
  formStateErrors,
  disabled,
  isIndividualSport,
  leagueId,
  handleFieldChange,
  savedTeam,
}: TeamsProps) => {
  useEffect(() => {
    if (savedTeam) {
      setTypedTeam(savedTeam.teamName || '');
      handleFieldChange('teamId', savedTeam?.teamId);
      handleFieldChange('teamName', savedTeam?.teamName);
    }
  }, [savedTeam?.id, setTypedTeam]);

  const teamValue = useCallback(
    (field: ControllerRenderProps<TeamFormInputs, 'teamId'>) => {
      return teams.find(el => el.idTeam === field.value) || null;
    },
    [teams]
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setTypedTeam(value);
    handleFieldChange('teamName', value);
    handleFieldChange('teamId', null);
  };

  const handleSelectChange = (
    event: React.SyntheticEvent,
    newValue: ITeamsByLeagueNameResponse | string | null
  ) => {
    if (newValue && typeof newValue === 'object') {
      handleFieldChange('teamId', newValue.idTeam);
      handleFieldChange('teamName', newValue.strTeam);
      setTypedTeam(newValue.strTeam);
    } else if (typeof newValue === 'string') {
      handleFieldChange('teamName', newValue);
      handleFieldChange('teamId', null);
      setTypedTeam(newValue);
    }
  };

  return (
    <Controller
      name="teamId"
      control={control}
      render={({ field }) => (
        <FormControl
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="outlined"
          fullWidth
          error={!!formStateErrors.teamId}
        >
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
              color: field.disabled ? 'text.disabled' : 'text.primary',
            }}
          >
            {isIndividualSport ? 'Club:' : 'Team:'}
          </Typography>

          <Autocomplete
            freeSolo
            defaultValue={teamValue(field)}
            value={teamValue(field) || typedTeam}
            inputValue={typedTeam}
            onInputChange={(event, newInputValue) => {
              setTypedTeam(newInputValue);
              handleFieldChange('teamName', newInputValue);
              handleFieldChange('teamId', null);
            }}
            loading={isLoading}
            options={teams ?? []}
            getOptionLabel={option =>
              typeof option === 'string' ? option : option.strTeam || ''
            }
            isOptionEqualToValue={(option, value) =>
              typeof value === 'object' && option.idTeam === value?.idTeam
            }
            onChange={(event, newValue) => handleSelectChange(event, newValue)}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Search or enter a team"
                error={!!formStateErrors.teamId}
                helperText={
                  formStateErrors.teamId ? formStateErrors.teamId.message : ''
                }
                disabled={isLoading || disabled}
                onChange={handleInputChange}
              />
            )}
            renderOption={(props, option) => (
              <AutocompleteOption text={option.strTeam} props={props} />
            )}
            disabled={isLoading || disabled}
            sx={{
              '.MuiInputBase-root': {
                height: '50px',
              },
              '.MuiSvgIcon-root': {
                bgcolor: 'transparent !important',
              },
            }}
            popupIcon={<ArrowDownIcon />}
          />
        </FormControl>
      )}
    />
  );
};

export default Teams;
