import Footer from '../../../components/Header/Footer/Footer';
import CompanyRegistration from './components/CompanyRegistration';

const CompanyRegistrationPage = () => {
  return (
    <>
      <CompanyRegistration />
      <Footer />
    </>
  );
};

export default CompanyRegistrationPage;
