import { Box, Divider, Typography } from '@mui/material';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IUserSportBackgroundResponse } from '../../interfaces/user-sport-background.interface';
import SectionLayout from '../../layouts/SectionLayout';
import EditSportBackground from '../../pages/auth/ProfilePage/sport-background/EditSportBackground';
import { useGetMyUserSportBackgroundsQuery } from '../../store/apiSlice/sportBackgroundApi';
import SportBackground from './Forms/SportBackgroundForm/SportBackground';
import { useNavigate } from 'react-router-dom';

const SportBackgroundsSection = () => {
  const sportBgRefs = useRef<
    { sportBgId: string; element: HTMLDivElement | null }[]
  >([]);
  const editSportBgRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const [editSportBackground, setEditSportBackground] =
    useState<IUserSportBackgroundResponse | null>(null);

  const {
    data: sportBgs,
    isLoading: isSportBgsLoading,
    error: sportBgsError,
    refetch: refetchSportBgs,
  } = useGetMyUserSportBackgroundsQuery();

  const sortedBgs = useMemo(() => {
    if (!sportBgs) {
      return [];
    }

    return [...sportBgs].sort(
      (a, b) =>
        new Date(b.startCareerDate).getTime() -
        new Date(a.startCareerDate).getTime()
    );
  }, [sportBgs]);

  useEffect(() => {
    if (sportBgs && sportBgs.length === 0)
      navigate('/welcome', { replace: true });
  }, [sportBgs]);

  useEffect(() => {
    if (editSportBackground) {
      setTimeout(() => {
        if (editSportBgRef.current) {
          editSportBgRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, [editSportBackground]);

  const openSportBackgroundEdit = useCallback(
    (sportBg: IUserSportBackgroundResponse | null) => () => {
      setEditSportBackground(sportBg);
    },
    []
  );

  const closeSportBackgroundEdit = useCallback(
    (sportBgId: string) => {
      refetchSportBgs();
      openSportBackgroundEdit(null)();

      setTimeout(() => {
        if (sportBgRefs.current.length) {
          sportBgRefs.current
            .find(el => el.sportBgId === sportBgId)
            ?.element?.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    },
    [openSportBackgroundEdit, refetchSportBgs]
  );

  return (
    <SectionLayout>
      <Typography component="h2" variant="h2" mb={6}>
        Sport background{sportBgs && sportBgs?.length > 1 && 's'}
      </Typography>
      <Box display="flex" flexDirection="column" rowGap={6}>
        {!editSportBackground &&
          !!sportBgs?.length &&
          sortedBgs.map((sportBg, index) => (
            <Fragment key={sportBg.id}>
              <SportBackground
                ref={el =>
                  (sportBgRefs.current[index] = {
                    sportBgId: sportBg.id,
                    element: el,
                  })
                }
                sportBg={sportBg}
                openSportBackgroundEdit={openSportBackgroundEdit(sportBg)}
              />
              {index !== sportBgs.length - 1 && <Divider />}
            </Fragment>
          ))}
      </Box>

      {editSportBackground && (
        <EditSportBackground
          ref={editSportBgRef}
          sportBg={editSportBackground}
          openSportBackgroundEditClose={closeSportBackgroundEdit}
        />
      )}
    </SectionLayout>
  );
};

export default SportBackgroundsSection;
