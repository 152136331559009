import { Box } from '@mui/material';
import { useMemo } from 'react';
import { GetAllApplicantsRes } from '../../../../../../interfaces/user.interface';
import UserListItem, {
  UserItemStatus,
} from '../../../components/user-list/UserListItem';
import SkeletonTabContent from '../../SkeletonTabContent';
import ActionButtons from './ActionButtons';

interface ApplicantsListProps {
  applicants?: GetAllApplicantsRes['data'];
  isLoading?: boolean;
}

const ApplicantsList = ({ applicants, isLoading }: ApplicantsListProps) => {
  const formatedApplicants = useMemo(
    () =>
      applicants?.map(app => {
        const userItemStatus = UserItemStatus.ACTIVE;
        const userInfo = {
          id: app.id,
          avatarSrc: app?.optimizedUserAvatarBaseUrl
            ? `data:image/jpeg;base64,${app.optimizedUserAvatarBaseUrl}`
            : '',
          firstName: app.firstName ?? '',
          lastName: app.lastName ?? '',
          sportNames: app.sports.map(s => s.name),
        };

        return { userInfo, userItemStatus };
      }),
    [applicants]
  );

  return (
    <Box component="ul" className="applicants-user-list">
      {isLoading && <SkeletonTabContent />}
      {!isLoading &&
        !!formatedApplicants?.length &&
        formatedApplicants.map((p, index) => (
          <UserListItem
            key={index}
            Actions={ActionButtons}
            userInfo={p.userInfo}
            userItemStatus={p.userItemStatus}
          />
        ))}
    </Box>
  );
};

export default ApplicantsList;
