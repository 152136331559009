import { useParams } from 'react-router-dom';
import { useGetPostByIdQuery } from '../../../store/apiSlice/post/postApi';
import ProfilePost from '../shared-components/posts/ProfilePost';
import { Container, Box, Typography } from '@mui/material';

const ViewPostPage = () => {
  const { id } = useParams();
  const {
    data: post,
    isLoading: isPostLoading,
    refetch: refetchPosts,
  } = useGetPostByIdQuery(id || '', {
    skip: !id,
  });

  return (
    <Container
      disableGutters
      className="view-post-page__container"
      sx={{
        maxWidth: '1320px !important',
      }}
    >
      <Box
        className={'view-post-page__layout-container'}
        display="flex"
        flexDirection="row"
        gap={{ lg: '16px', xl: '22px' }}
        width="100%"
        bgcolor="white"
      >
        {!post && !isPostLoading && (
          <Box
            height="calc(100vh - 268px)"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="80px"
          >
            <Typography fontSize={100}>404</Typography>
            <Typography variant="h2">Oops! This page not found!</Typography>
          </Box>
        )}
        {post && <ProfilePost post={post} refetchPosts={refetchPosts} />}
      </Box>
    </Container>
  );
};

export default ViewPostPage;
