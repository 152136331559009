import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, FormControl, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import Input from '../../../../components/MUIComponents/Input';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { Suggestion } from '../../../../components/shared/Mentions/MentionsTextField/MentionsTextField';
import MentionsTextFieldWrapper from '../../../../components/shared/Mentions/MentionsTextFieldWrapper/MentionsTextFieldWrapper';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { IPost } from '../../../../interfaces/post.interface';
import {
  useGetAllMyChatsQuery,
  useSendMessageWithRepostMutation,
} from '../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useGetFriendsByUserIdQuery } from '../../../../store/apiSlice/friendApi';
import { useCreatePostMutation } from '../../../../store/apiSlice/post/postApi';
import userApi from '../../../../store/apiSlice/userApi';
import { errorHelper } from '../../../../utils/helper/error-helper';
import {
  RepostMethod,
  repostSchema,
} from '../../../../validation/post-validation';
import RepostedPost from '../posts/RepostedPost';
import { SelectItemUser } from './components/SelectItemUser';

interface RepostModalProps {
  isOpen?: boolean;
  onClose: () => void;
  post?: IPost;
  isShare?: boolean;
}

interface RepostFormInputs {
  mentions?: Suggestion[];
  content?: string;
  title?: string | null;
  repostedPostId?: string | null;
  shareOption: RepostMethod;
  friendIdToMessage?: string;
}

const RepostModal: React.FC<RepostModalProps> = ({
  isOpen = false,
  onClose,
  post,
  isShare = false,
}) => {
  const [repostMethod] = useState<RepostMethod>(
    isShare ? RepostMethod.BY_MESSAGE : RepostMethod.ON_WALL
  );
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RepostFormInputs>({
    resolver: yupResolver(repostSchema),
    defaultValues: {
      repostedPostId: post ? post.id : '',
      shareOption: RepostMethod.ON_WALL,
    },
  });

  const { data: myData } = useSelector(userApi.endpoints.getMyProfile.select());
  const { data: myFriends, isLoading: isMyFriendsLoading } =
    useGetFriendsByUserIdQuery({}, { skip: !post });

  const [createPost, { isLoading: isCreating }] = useCreatePostMutation();
  const [sendMessageWithRepost, { isLoading: isSending }] =
    useSendMessageWithRepostMutation();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { data: myChats, refetch: refetchChats } = useGetAllMyChatsQuery();
  const dispatch = useDispatch();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async (data: RepostFormInputs) => {
    try {
      if (repostMethod === RepostMethod.ON_WALL) {
        const formData = new FormData();
        data.title = Date.now().toString();

        formData.append('title', data.title);
        formData.append('content', data?.content ?? '');

        data.mentions?.forEach((mention, index) => {
          (Object.keys(mention) as (keyof Suggestion)[]).forEach(key => {
            const typedKey = key as keyof Suggestion;
            const value =
              mention[typedKey] !== undefined ? String(mention[typedKey]) : '';

            return formData.append(`mentions[${index}][${key}]`, value);
          });
        });

        if (data.repostedPostId) {
          formData.append('repostedPostId', data.repostedPostId);
        }

        await createPost(formData).unwrap();
      }

      if (repostMethod === RepostMethod.BY_MESSAGE) {
        if (!data.friendIdToMessage) {
          throw new Error('Please select a friend to message');
        }

        if (data.repostedPostId && myData?.id && data.friendIdToMessage) {
          await sendMessageWithRepost({
            postId: data.repostedPostId,
            description: data?.content ?? '',
            fromUserId: myData.id,
            toUserId: data.friendIdToMessage,
          });

          await refetchChats();

          if (myChats) {
            const chat = myChats.find(
              chat => chat.withUserId === data.friendIdToMessage
            );
            if (chat) {
              dispatch(setSelectedChat(chat));
            }
          }
          dispatch(setChatState(ChatStateEnum.CHAT));
          navigate('/chat');

          onClose();
        }
      }

      setSnackbarOpen(true);
      setSnackbarMessage('Repost successful!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);

      onClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const modalTitle = useMemo(
    () => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant="h3">{isShare ? 'Share' : 'Repost'}</Typography>
        <Divider sx={{ m: '22px -28px 0' }} />
      </Box>
    ),
    [isShare]
  );

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Divider sx={{ m: '36px -28px 22px' }} />
        <Grid
          container
          justifyContent="flex-end"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '22px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '140px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '140px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isCreating || isSending}
            >
              {isShare ? 'Share' : 'Repost'}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [handleSubmit, isCreating, isSending, onClose, isShare]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      disableEscapeButton
      boxSx={{ width: { xs: '100%', lg: '648px' }, p: '22px 28px' }}
    >
      <Stack spacing="2.25rem">
        {isShare && (
          <FormControl sx={{ width: { xs: '100%', lg: '50%' } }}>
            <Input
              select
              containerFlex="1"
              caption="Message to:"
              variant="outlined"
              fullWidth
              placeholder="Choose someone"
              loading={isMyFriendsLoading}
              name="friendIdToMessage"
              error={!!errors.friendIdToMessage}
              helperText={errors.friendIdToMessage?.message}
              onSelectFieldChange={(value: any) =>
                setValue('friendIdToMessage', value.props.userId as string)
              }
            >
              {!!myFriends?.friends.length &&
                myFriends.friends.map(item => (
                  <SelectItemUser
                    key={item.userId}
                    userId={item.userId}
                    avatarUrl={item.avatarUrl || ''}
                    firstName={item.firstName || ''}
                    lastName={item.lastName || ''}
                    sport={item.sports[0]?.name}
                  />
                ))}
            </Input>
          </FormControl>
        )}
        <Box>
          <Typography component="p" fontSize="12px" fontWeight={700} mb="16px">
            Add a quote:
          </Typography>
          <MentionsTextFieldWrapper
            minHeight={100}
            setMentions={mentions => setValue('mentions', mentions)}
            placeholder="Add your thoughts here."
            maxLength={100}
            register={register('content')}
            error={!!errors.content}
            helperText={errors.content ? errors.content.message : ''}
          />
        </Box>

        {post && <RepostedPost previewPost={post} />}
      </Stack>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default RepostModal;
