import { ISportResponse } from '../../../../../interfaces/sport.interface';
import { Box, Typography } from '@mui/material';
import SportListItem from './SportListItem';
import { colors } from '../../../../../theme/theme';
import { useMemo } from 'react';
import { OTHER_SPORT } from '../../../../../constants';

interface IProps {
  sports: ISportResponse[];
  selectedSportId: string | null;
  handleSportToggle: (sportId: string) => void;
  isEditSportBg?: boolean;
}

const SportList = (props: IProps) => {
  const { sports, selectedSportId, handleSportToggle, isEditSportBg } = props;

  const extendedSports = useMemo(() => {
    if (sports.length === 0) {
      return [];
    }

    const temp = sports.slice();
    const otherSport: ISportResponse = OTHER_SPORT as ISportResponse;
    temp.push(otherSport);

    return temp;
  }, [sports]);

  if (sports.length === 0) {
    return (
      <Typography style={{ color: colors.white }}>
        No sports available
      </Typography>
    );
  }

  const lastSport = extendedSports[extendedSports.length - 1];

  return (
    <Box
      display="grid"
      sx={theme => ({
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1.375rem',

        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        },
      })}
    >
      {extendedSports.map(sport => (
        <SportListItem
          key={sport.id}
          sport={sport}
          selectedSportId={
            extendedSports.findIndex(
              extendedSport => extendedSport?.id === selectedSportId
            ) === -1 && isEditSportBg
              ? lastSport?.id
              : selectedSportId
          }
          handleSportToggle={handleSportToggle}
          isEditSportBg={isEditSportBg}
        />
      ))}
    </Box>
  );
};

export default SportList;
