import { Box } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { IUserFileDB } from '../../../interfaces/file.interface';
import { ISportBackgroundFormData } from '../../../interfaces/user-sport-background.interface';
import {
  IVerifyUserProfileFormData,
  IVerifyUserProfileRequest,
} from '../../../interfaces/user.interface';
import { useCreateCareerMutation } from '../../../store/apiSlice/careerApi';
import { useCreateUserSportBackgroundMutation } from '../../../store/apiSlice/sportBackgroundApi';
import { useVerifyUserProfileMutation } from '../../../store/apiSlice/userApi';
import { useUploadIdConfirmationFilesMutation } from '../../../store/apiSlice/userFilesApi';
import { errorHelper } from '../../../utils/helper/error-helper';
import {
  createSportBackgroundSchema,
  createTeamSchema,
} from '../../../validation/sport-validation';
import CustomStepper from '../../MUIComponents/CustomStepper';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import UploadDocument from '../../shared/UploadDocumentForm/UploadDocument';
import PersonalInformationForm from './PersonalInformationForm/PersonalInformationForm';
import SportVerification from './SportVerification/SportVerification';
import { UserRoleEnum } from '../../../enums/user-data-field-enum';
import { OTHER_SPORT } from '../../../constants';

interface RegistrationFormInputs {
  firstName: string;
  lastName: string;
  birthdate: Dayjs;
}

interface FileUploadFormInputs {
  documents: File[];
}

interface IVerifyAccountProps {
  role?: UserRoleEnum;
}

const VerifyAccount = (props: IVerifyAccountProps) => {
  const { role } = props;
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<Partial<IVerifyUserProfileRequest>>(
    {}
  );
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IUserFileDB[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const [verifyUserProfile, { isLoading, isError, error }] =
    useVerifyUserProfileMutation();

  const [
    uploadIdConfirmationFiles,
    {
      isLoading: idConfirmationFilesIsLoading,
      isError: idConfirmationFilesIsError,
      error: idConfirmationFilesError,
      data: idConfirmationFilesResponseData,
    },
  ] = useUploadIdConfirmationFilesMutation();

  const [createSportBackground] = useCreateUserSportBackgroundMutation();
  const [createCareer] = useCreateCareerMutation();

  const onSubmitUser: SubmitHandler<RegistrationFormInputs> = data => {
    setFormData({ ...formData, ...data });
    setStep(step + 1);
  };
  const onSubmitFiles: SubmitHandler<FileUploadFormInputs> = data => {
    const finalData = { ...formData, ...data };
    setFormData(finalData);
    setStep(step + 1);
  };

  const onSubmitSport: SubmitHandler<ISportBackgroundFormData> = async data => {
    setIsSubmitting(true);
    const finalData = { ...formData, ...data } as IVerifyUserProfileFormData;

    if (role === UserRoleEnum.USER) {
      try {
        // Handle check sportBackgrounds
        for (const sportBackground of finalData.sportBackgrounds) {
          // to do sport(3) : add sportBackgroundSchema validation
          try {
            // console.log(sportBackground);

            await createSportBackgroundSchema(
              sportBackground.isOtherSport ?? false
            ).validate(sportBackground, {
              stripUnknown: true,
              abortEarly: false,
            });
          } catch (validationErrors: any) {
            const errorMessages: any[] = validationErrors.inner.map(
              (error: any) => ({
                path: error.path,
                message: error.message,
              })
            );
            const errorToString = errorMessages.reduce<string>(
              (acc, cur) => acc + `${cur.message}\n`,
              ''
            );
            throw new Error(errorToString);
          }
          // Handle check teams at a sport background
          for (const team of sportBackground.teams || []) {
            try {
              // console.log('team', JSON.stringify(team));
              await createTeamSchema(
                sportBackground.isOtherSport ?? false
              ).validate(team, {
                abortEarly: false,
              });
            } catch (validationErrors: any) {
              const errorMessages: any[] = validationErrors.inner.map(
                (error: any) => ({
                  path: error.path,
                  message: error.message,
                })
              );
              const errorToString = errorMessages.reduce<string>(
                (acc, cur) => acc + `${cur.message}\n`,
                ''
              );
              throw new Error(errorToString);
            }
          }
        }
      } catch (error) {
        setIsSubmitting(false);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
        return;
      }
      const sportIds = [] as string[];

      const uniqueSportIds = new Set();

      finalData.sportBackgrounds.forEach(sportBackground => {
        if (!uniqueSportIds.has(sportBackground.sportId)) {
          uniqueSportIds.add(sportBackground.sportId);
          if (sportBackground.sportId.length > 0) {
            sportIds.push(sportBackground.sportId);
          }
        }
      });

      if (sportIds.length === 0) {
        setSnackbarMessage('Please select at least one sport.');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
        return;
      }
    }

    const userVerificationData = {
      firstName: finalData.firstName,
      lastName: finalData.lastName,
      birthdate: finalData.birthdate?.toISOString(),
    };

    try {
      await verifyUserProfile(userVerificationData).unwrap();
      // TODO upload files will be changed at the future then will be create one time link
      // Handle save files
      if (files.length > 0) {
        const formData = new FormData();
        files.forEach(file => {
          formData.append('documents', file);
        });
        await uploadIdConfirmationFiles(formData).unwrap();
      }
      // Handle save sport backgrounds
      if (role === UserRoleEnum.USER) {
        await Promise.all(
          finalData.sportBackgrounds.map(async sportBackground => {
            const sportBackgroundData = new FormData();

            // if user select Other sport
            if (sportBackground.sportId === OTHER_SPORT.id) {
              sportBackground.sportName &&
                sportBackgroundData.append(
                  'otherSportName',
                  sportBackground.sportName
                );
              sportBackground.isIndividualSport &&
                sportBackgroundData.append(
                  'isIndividual',
                  String(sportBackground.isIndividualSport)
                );
            } else {
              sportBackgroundData.append('sportId', sportBackground.sportId);
            }

            if (sportBackground.description) {
              sportBackgroundData.append(
                'description',
                sportBackground.description
              );
            }

            if (sportBackground.startCareerDate) {
              sportBackgroundData.append(
                'startCareerDate',
                new Date(sportBackground.startCareerDate).toISOString()
              );
            }

            if (sportBackground.endCareerDate) {
              sportBackgroundData.append(
                'endCareerDate',
                new Date(sportBackground.endCareerDate).toISOString()
              );
            }

            if (
              sportBackground.fileData &&
              sportBackground.fileData.length > 0
            ) {
              sportBackground.fileData.forEach((fileData, index) => {
                sportBackgroundData.append('documents', fileData.file);
                if (fileData.description) {
                  sportBackgroundData.append(
                    `documents[${index}].description`,
                    fileData.description
                  );
                }
                if (fileData.links && fileData.links.length > 0) {
                  fileData.links.forEach(link => {
                    sportBackgroundData.append(
                      `documents[${index}].links[]`,
                      link
                    );
                  });
                }
              });
            }

            const createdSporgBg =
              await createSportBackground(sportBackgroundData).unwrap();

            // to do sport(2): add check if sportBackground.clubs create new Clubs
            if (sportBackground.teams) {
              await Promise.all(
                sportBackground.teams.map(async team => {
                  return createCareer({
                    sportId: createdSporgBg.sportId,
                    userSportBackgroundId: createdSporgBg.id,
                    idLeague: team.leagueId ?? '',
                    idTeam: team.teamId ?? '',
                    leagueName: team.leagueName ?? '',
                    teamName: team.teamName ?? '',
                    startDate: new Date(team.startDateInTeam).toISOString(),
                    endDate: new Date(team.endDateInTeam).toISOString(),
                  });
                })
              );
            }
          })
        )
          .then(() => {
            setSnackbarMessage('Profile verification successful.');
            setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
            navigate('/welcome-to-platform', { replace: true });
          })
          .catch(error => {
            const errorMessage = errorHelper(error);
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
            setSnackbarOpen(true);
          });
      }
      setSnackbarMessage('Profile verification successful.');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      if (role === UserRoleEnum.AGENT) {
        navigate('/');
      }
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
    setIsSubmitting(false);
  };

  const steps =
    role && role === UserRoleEnum.AGENT
      ? ['Personal Information', 'ID Confirmation']
      : ['Personal Information', 'ID Confirmation', 'Sport Background'];

  return (
    <Box
      display={'flex'}
      maxWidth={'875px'}
      margin={'0 auto'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={'64px'}
    >
      <CustomStepper steps={steps} step={step} />

      {step === 1 && (
        <PersonalInformationForm
          onSubmitUser={onSubmitUser}
          formData={formData as RegistrationFormInputs}
          setStep={setStep}
          step={step}
        />
      )}
      {step === 2 && (
        <UploadDocument
          title={'ID Confirmation'}
          contentText={
            <>
              To complete your registration, please upload a valid ID document
              or
              <br />
              driver’s license for verification.
              <br />
              This ensures the authenticity of your profile.
            </>
          }
          dragAndDropContentText={
            <>
              Drag and drop your ID document or driver’s license here to
              complete your registration.
              <br />
              Ensure the document is clear and legible for verification
              purposes.
              <br />
              You can upload up to 5 files. Only images (JPEG, PNG, JPG, WebP)
              are accepted.
            </>
          }
          onSubmitFiles={
            role === UserRoleEnum.AGENT ? onSubmitSport : onSubmitFiles
          }
          formData={formData as FileUploadFormInputs}
          setStep={setStep}
          step={step}
          files={files}
          setFiles={setFiles}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          isLoading={idConfirmationFilesIsLoading}
          isError={idConfirmationFilesIsError}
          error={idConfirmationFilesError}
          responseData={idConfirmationFilesResponseData}
        />
      )}
      {step === 3 && (
        <SportVerification
          onSubmitSport={onSubmitSport}
          formData={formData as ISportBackgroundFormData}
          setStep={setStep}
          step={step}
          isLoading={isSubmitting}
        />
      )}

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default VerifyAccount;
